import PriceCriteria, {
     create as createPrice,
     getDefaults as getPriceDefaults,
     getDisplay as getPriceDisplay,
     getQuery as getPriceQuery,
     hasChanged as hasPriceChanged,
} from "Components/SearchCriteria/PriceCriteria";

import PropertyTypeCriteria, {
     create as createPropertyType,
     getDefaults as getPropertyTypeDefaults,
     getDisplay as getPropertyTypeDisplay,
     criteriaPropsSelector as propertyTypeCriteriaPropsSelector,
     getQuery as getPropertyTypeQuery,
     hasChanged as propertyTypeHasChanged,
} from "Components/SearchCriteria/PropertyTypeCriteria";

import NumberRangeCriteria, {
     create as createNumberRange,
     getDefaults as getNumberRangeDefaults,
     getDisplay as getNumberRangeDisplay,
     getQuery as getNumberRangeQuery,
     hasChanged as numberRangeHasChanged,
} from "Components/SearchCriteria/NumberRangeCriteria";

import TextCriteria, {
     create as createText,
     getDefaults as getTextDefaults,
} from "Components/SearchCriteria/TextCriteria";

import WaterCriteria, {
     create as createWaterCriteria,
     getDefaults as getWaterDefaults,
     getDisplay as getWaterDisplay,
     criteriaPropsSelector as waterCriteriaPropsSelector,
     getQuery as getWaterQuery,
     hasChanged as hasWaterChanged,
} from "Components/SearchCriteria/WaterCriteria";

import {
     create as createStatus,
     getDefaults as getStatusDefaults,
} from "Components/SearchCriteria/StatusCriteria";

import {
     create as createListAgent,
     getDefaults as getListAgentDefaults,
} from "Components/SearchCriteria/ListAgentCriteria";

let CriteriaExports = {
     City: {
          create: createText.bind(null, "City"),
          component: TextCriteria,
          componentProps: {
               label: "City",
               criteria: "City",
          },
          getDefaults: getTextDefaults,
          criteriaPropsSelector: null,
          primary: true,
     },
     ListPrice: {
          create: createPrice,
          component: PriceCriteria,
          componentProps: {
               label: "price",
               criteria: "ListPrice",
          },
          getDefaults: getPriceDefaults,
          getDisplay: getPriceDisplay,
          getQuery: getPriceQuery,
          criteriaPropsSelector: null,
          hasChanged: hasPriceChanged,
     },
     PropertyType: {
          create: createPropertyType,
          component: PropertyTypeCriteria,
          componentProps: {
               label: "Property Type",
               criteria: "PropertyType",
          },
          getDefaults: getPropertyTypeDefaults,
          getDisplay: getPropertyTypeDisplay,
          criteriaPropsSelector: propertyTypeCriteriaPropsSelector,
          getQuery: getPropertyTypeQuery,
          hasChanged: propertyTypeHasChanged,
     },
     Status: {
          create: createStatus,
          component: null,
          componentProps: {
               label: "Status",
               criteria: "MlsStatus",
          },
          getDefaults: getStatusDefaults,
          criteriaPropsSelector: null,
     },
     ListAgent: {
          create: createListAgent,
          component: null,
          componentProps: {
               label: "Listing Agent",
               criteria: "ListAgent",
          },
          getDefaults: getListAgentDefaults,
          criteriaPropsSelector: null,
     },
     BedsTotal: {
          create: createNumberRange,
          component: NumberRangeCriteria,
          componentProps: {
               label: "Beds",
               criteria: "BedsTotal",
               range: 4,
          },
          getDefaults: getNumberRangeDefaults,
          getDisplay: getNumberRangeDisplay,
          getQuery: getNumberRangeQuery,
          criteriaPropsSelector: null,
          hasChanged: numberRangeHasChanged,
     },
     BathsTotal: {
          create: createNumberRange,
          component: NumberRangeCriteria,
          componentProps: {
               label: "Baths",
               criteria: "BathsTotal",
               range: 3,
          },
          getDefaults: getNumberRangeDefaults,
          getDisplay: getNumberRangeDisplay,
          getQuery: getNumberRangeQuery,
          criteriaPropsSelector: null,
          hasChanged: numberRangeHasChanged,
     },
     // TODO: fix water
     Water: {
          create: createWaterCriteria,
          component: WaterCriteria,
          componentProps: {
               label: "Water",
               criteria: "Water",
          },
          getDefaults: getWaterDefaults,
          getDisplay: getWaterDisplay,
          getQuery: getWaterQuery,
          criteriaPropsSelector: waterCriteriaPropsSelector,
          hasChanged: hasWaterChanged,
     },
};

CriteriaExports.Array = Object.keys(CriteriaExports).map(
     (k) => CriteriaExports[k]
);

export default CriteriaExports;
