import React, { useEffect, useRef } from "react";
import * as ReactIs from "react-is";

import { Link } from "react-router-dom";

// misc
import { cloneDeep, findIndex, get } from "lodash";
import { useMediaQuery } from "react-responsive";

// redux
import { useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     InputAdornment,
     TextField,
     Typography as Text,
} from "@material-ui/core";

// styling bits

import { makeStyles } from "@material-ui/core/styles";

import {
     IsSmall,
     IsMedium,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

const styles = makeStyles({
     root: {
          display: "flex",
          padding: "0 8px 0 8px",
     },
     text: (props) => ({
          width: props.isSmall ? null : "100px",
     }),
     dash: {
          fontSize: "14px",
          margin: "auto",
     },
});

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

export const getDisplay = (values = [null, null]) => {
     let low = values[0];
     let high = values[1];

     let display = "";
     if (low && high) display = `$${low}k - $${high}k`;
     if (low && !high) display = `> $${low}k`;
     if (high && !low) display = `< $${high}k`;

     return display;
};

export const getQuery = (values = [null, null]) => {
     let low = values[0];
     let high = values[1];

     // if both values are set
     if (low && high)
          return `ListPrice Ge ${low * 1000} And ListPrice Le ${high * 1000}`;

     if (low && !high) return `ListPrice Ge ${low * 1000}`;

     if (!low && high) return `ListPrice Le ${high * 1000}`;
};

export const create = (value = [null, null], props) => {
     //onsole.log("PriceCriteria.create", new Error().stack);

     return {
          criteria: "ListPrice",
          value: value,
          default: getDefaults(props),
          display: getDisplay(value),
          query: getQuery(value),
     };
};

export const getDefaults = (props) => {
     return [null, null];
};

export const hasChanged = (prev, curr) => {
     if (prev == null || curr == null) return false;

     return prev[0] != curr[0] || prev[1] != curr[1];
};

export const PriceCriteria = (props) => {
     const { classes: userClasses, criteria, onEnter } = props;

     // hooks

     const state = useSelector((s) =>
          s.search.criteria.find((c) => c.criteria == criteria)
     );
     const dispatch = useDispatch();

     const isSmall = useMediaQuery(IsSmall);
     const isMediumPlus = useMediaQuery(IsMediumPlus);
     const classes = styles({ isSmall: isSmall });

     const lowInputRef = useRef();
     const highInputRef = useRef();

     // handlers / callbacks
     const handleOnChange = (index, e) => {
          let newValues = cloneDeep(state.value);
          let value = Number.parseInt(e.target.value, 10);

          if (Number.isNaN(value)) value = 0;

          newValues[index] = value;

          const display = getDisplay(newValues);

          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: {
                    criteria: criteria,
                    value: newValues,
                    display: display,
                    default: [null, null],
                    query: getQuery(newValues),
               },
          });
     };

     const validate = () => {
          if (state.value[0] && state.value[1]) {
               let v = cloneDeep(state.value);
               let x = 0;

               // swap
               if (v[0] > v[1] || v[1] < v[0]) {
                    x = v[0];
                    v[0] = v[1];
                    v[1] = x;
               }

               const display = getDisplay(v);

               dispatch({
                    type: "SET_SEARCH_CRITERIA",
                    data: {
                         criteria: criteria,
                         value: v,
                         display: display,
                         default: [null, null],
                         query: getQuery(v),
                    },
               });
          }
     };

     const low = get(state, "value[0]", null);
     const high = get(state, "value[1]", null);

     const handleKeyPress = (e) => {
          if (e.key === "Enter" && onEnter) {
               validate();
               onEnter(e);
          }
     };

     useEffect(() => {
          lowInputRef.current.value = low;
     }, [low]);

     useEffect(() => {
          highInputRef.current.value = high;
     }, [high]);

     return (
          <div className={classes.root}>
               <TextField
                    margin="dense"
                    label="low"
                    variant="outlined"
                    classes={{ root: classes.text }}
                    onChange={handleOnChange.bind(null, 0)}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                         endAdornment: (
                              <InputAdornment position="end">k</InputAdornment>
                         ),
                         style: { fontSize: "16px" },
                    }}
                    //value={low}
                    inputRef={lowInputRef}
               />
               <Text classes={{ root: classes.dash }}>&nbsp;-&nbsp;</Text>
               <TextField
                    margin="dense"
                    label="high"
                    variant="outlined"
                    classes={{ root: classes.text }}
                    onChange={handleOnChange.bind(null, 1)}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                         endAdornment: (
                              <InputAdornment position="end">k</InputAdornment>
                         ),
                         style: { fontSize: "16px" },
                    }}
                    //value={high}
                    inputRef={highInputRef}
               />
          </div>
     );
};

export default PriceCriteria;
