import React, { useState, useEffect, useRef } from "react";
import * as ReactIs from "react-is";

// redux
import { useSelector } from "react-redux";

// misc

import get from "lodash/get";

// custom hooks
import useOnScreen from "../Hooks/useOnScreen";

// material ui imports
import { Paper, Typography, Typography as Text } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { SportsRugbySharp } from "@material-ui/icons";

const styles = makeStyles({
     root: {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "350px",
          margin: "0",
          borderRadius: "4px",
          boxShadow:
               "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
     },
     centerContainer: {
          position: "relative",
          margin: "auto",
          width: "100%",
          height: "100%",
          //border: "1px solid red",
     },
     homeIcon: {
          position: "absolute",
          //margin: "auto",
          width: "100%",
          height: "100%",
          color: "rgba(0,0,0,0.10)",
     },
     text: {
          position: "absolute",
          left: "50%",
          marginLeft: "-34px",
          top: "50%",
          //margin: "auto",
     },
});

export const NoResultsTile = (props) => {
     const { classes: userClasses, listing } = props;

     const hasSearched = useSelector((s) => s.search.hasSearched);

     const classes = styles();

     const BodyText = (props) => (
          <Typography
               variant="body1"
               style={{ color: window.theme.palette.text.primary }}
               {...props}
          >
               <>{props.children}</>
          </Typography>
     );

     return (
          <BodyText>
               {hasSearched
                    ? `We didn't find any listings matching your search. Try adjusting your criteria and searching again.`
                    : ""}
          </BodyText>
     );
};

export default NoResultsTile;
