export const criteriaSelector = (state) => {
     return null;
};

export const getDefaults = (props) => {
     return ["Closed"];
};

export const getQuery = (value) => {
     if (value == null) return "";
     else return `(${value.map((v) => `MlsStatus Ne '${v}'`)})`;
};

export const create = (value, props) => {
     return {
          criteria: "MlsStatus",
          value: value,
          default: getDefaults(),
          display: null,
          query: getQuery(value),
     };
};
