import React, { useState, useEffect } from "react";
import * as ReactIs from "react-is";

import { Link, useHistory, useLocation } from "react-router-dom";

// misc
import { get } from "lodash";
import { useMediaQuery } from "react-responsive";

// redux
import { useSelector, useDispatch } from "react-redux";

// material ui imports
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Divider, Drawer, List, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import CloseIcon from "@material-ui/icons/Close";

import {
     IsSmall,
     IsMedium,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

// realty
import SearchCriteria from "Components/SearchCriteria/SearchCriteria";
import CriteriaExports from "Components/SearchCriteria/CriteriaExports";

import Paging from "Components/Paging";
import Sort from "Components/Sort";

import MobileFilter from "Components/MobileFilter";

import { searchThunk } from "SearchUtility";

const styles = makeStyles({
     root: (props) => ({
          border: "0px solid red",
          display: "flex",
          flexDirection: props.stackChildren ? "column" : "row",
          margin: !props.hideCriteria ? "0" : "auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          padding: "12px",
          width: !props.hideCriteria && props.isSmall ? "100%" : "auto",
          position: "sticky", // sticky header
          top: 0,
     }),

     detailViewRoot: {
          display: "flex",
          width: "100%",
          position: "sticky", // sticky header,
          top: 0,
     },

     // the criteria container
     criteriaContainer: (props) => ({
          display: "flex",
          width: "auto",
          maxWidth: "850px",
          border: "0px fashed green",
     }),
     collapseCriteria: {
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "8px",
     },
     searchRoot: (props) => ({
          //minWidth: props.isSmall ? null : "150px",
          flexGrow: 1,
          //border: "1px solid red",
     }),
     searchFilterContainer: (props) => ({
          //border: "1px solid blue",
          margin: props.isSmall ? "0px 12px 0px auto" : null,
     }),
     filterIconRoot: {
          margin: "0px",
     },
     searchIconRoot: {
          margin: "0px ",
     },

     buttonRoot: {
          background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          minWidth: "100px",
          flexWrap: "wrap",
          //marginLeft: "auto"
     },
     filterRoot: {
          margin: "0px 24px 0px auto",
     },

     // passed into each individual criteria
     criteriaRoot: {
          marginLeft: "8px",
          maxWidth: "150px",
          //overflow: "hidden",
     },
     extraCriteria: (props) => ({
          //display: props.collapseCriteria ? "none" : "flex",
          display: "flex",
     }),

     pagingRoot: (props) => ({
          margin: props.stackChildren
               ? "8px auto 0px auto"
               : "0px 24px 0px auto",
     }),

     // icons / buttons
     closeButton: {
          margin: "0px 16px 0px auto",
     },
     closeIcon: {
          fontSize: 24,
     },
});

export const SearchBar = (props) => {
     // props
     let {
          hideCriteria = false,
          hideMobileCriteria = false,
          hideSort = false,
     } = props;

     // hooks
     const dispatch = useDispatch();
     const theme = useTheme();
     let history = useHistory();
     const location = useLocation();

     const isSmall = useMediaQuery(IsSmall);
     const isMedium = useMediaQuery(IsMedium);
     const isMediumPlus = useMediaQuery(IsMediumPlus);
     const paging = useSelector((s) => s.search.paging);
     const activeListing = useSelector((s) => s.search.activeListing);
     const [filtersActive, setFiltersActive] = useState(false);

     // set a few display flags based on component sizing
     let stackChildren = isSmall; //criteriaWidth + pagingWidth >= searchBarWidth;
     //let collapseCriteria = isSmall; //criteriaWidth >= searchBarWidth;

     const classes = styles({
          isSmall: isSmall,
          isMediumPlus: isMediumPlus,
          hideCriteria: hideCriteria,
          theme: theme,
          stackChildren: stackChildren,
          //collapseCriteria: collapseCriteria,
     });

     // effects

     // handlers / callbacks
     const resetPagingAndSearchThunk = () => {
          dispatch({ type: "RESET_PAGING" });
          dispatch(searchThunk);
     };

     const handleSearch = () => {
          dispatch(resetPagingAndSearchThunk);
     };

     const handleEnter = (e) => {
          if (e.key === "Enter") {
               if (location.pathname == "/") history.push("/search");

               handleSearch();
          }
     };

     const handleClose = () => {
          // null out the current listing
          dispatch({
               type: "SET",
               path: "search.activeListing",
               value: null,
          });
     };

     const handleToggleFilter = () => {
          setFiltersActive(!filtersActive);
     };

     const handleFilterBack = () => {
          setFiltersActive(false);
     };

     let criteriaProps = {
          classes: { root: classes.criteriaRoot },
          modal: isSmall, // criteria will show as a modal if we're on a small screen //collapseCriteria,
     };

     // markup

     if (activeListing != null) {
          return (
               <div className={classes.detailViewRoot}>
                    <Link
                         to="/search"
                         className={classes.closeButton}
                         onClick={handleClose}
                    >
                         <IconButton>
                              <CloseIcon
                                   classes={{ root: classes.closeIcon }}
                              />
                         </IconButton>
                    </Link>
                    <Divider />
               </div>
          );
     }

     const searchRootClasses = { root: classes.searchRoot };

     const primaryCriteria = CriteriaExports.Array.filter(
          (c) => c.primary == true
     ).map((c) => (
          <SearchCriteria
               label={c.componentProps.label}
               criteria={c.componentProps.criteria}
               getDefaults={c.getDefaults}
               hasChanged={c.hasChanged}
               usePopper={false}
               showLabel={false}
               searchOnChange={false}
               onKeyPress={handleEnter}
          >
               {React.createElement(c.component, c.componentProps)}
          </SearchCriteria>
     ));

     const secondaryCriteria = CriteriaExports.Array.filter(
          (c) => c.primary != true && c.component != null
     ).map((c) => {
          return (
               <SearchCriteria
                    label={c.componentProps.label}
                    criteria={c.componentProps.criteria}
                    getDefaults={c.getDefaults}
                    hasChanged={c.hasChanged}
                    {...criteriaProps}
               >
                    {React.createElement(c.component, c.componentProps)}
               </SearchCriteria>
          );
     });

     const searchBtn = (
          <Button
               component={Link}
               to={location.pathname == "/" ? "/search" : location.pathname}
               variant="contained"
               color="primary"
               startIcon={<SearchIcon />}
               classes={{ root: classes.buttonRoot }}
               onClick={handleSearch}
          >
               Search
          </Button>
     );

     const mobileFilterBtn = (
          <IconButton
               classes={{ root: classes.filterIconRoot }}
               onClick={handleToggleFilter}
          >
               <SortIcon />
          </IconButton>
     );

     return (
          <div id="searchBar" className={classes.root}>
               <div id="criteria" className={classes.criteriaContainer}>
                    {primaryCriteria}
                    <div className={classes.searchFilterContainer}>
                         {searchBtn}
                         {/*assume that, if on a small screen and we've asked to hide criteria, show the mobile criteria button*/}
                         {isSmall && hideCriteria && !hideMobileCriteria
                              ? mobileFilterBtn
                              : null}
                    </div>

                    {hideCriteria ? null : (
                         <div className={classes.extraCriteria}>
                              {secondaryCriteria}
                         </div>
                    )}
               </div>
               <MobileFilter open={filtersActive} onBack={handleFilterBack} />
               {hideSort ? null : <Sort />}
               <Paging
                    classes={{ root: classes.pagingRoot }}
                    stackChildren={props.stackChildren}
               />
          </div>
     );
};

export default SearchBar;
