import React, { useEffect } from "react";
import * as ReactIs from "react-is";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";

import { Link } from "react-router-dom";

// misc
import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     Checkbox,
     FormGroup,
     FormControlLabel,
     Typography as Text,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
     IsSmall,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

const styles = makeStyles({
     root: {
          display: "flex",
          //marginTop: "16px",
          //padding: "8px",
     },
});

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

export const getQuery = (values, props) => {
     const { propertyTypes } = props;

     if (values.length == 0) {
          const all = propertyTypes
               .map((p) => `PropertyType Eq '${p.value}'`)
               .join(" Or ");

          return `(${all})`;
     }

     let query = [];
     values.forEach((v) => {
          if (Array.isArray(v)) {
          } else query.push(`PropertyType Eq '${v}'`);
     });
     return query.length > 1 ? `(${query.join(" Or ")})` : `${query[0]}`;
};

// expects an array of property type codes, like [A,B,C]
export const create = (values, props = {}) => {
     return {
          criteria: "PropertyType",
          value: values,
          default: getDefaults(props),
          display: getDisplay(values, props),
          query: getQuery(values, props),
     };
};

// this query needs the types of property to be able to generate a query
// state = redux state
// props = optional additional props, defined in CriteriaExpors
export const criteriaPropsSelector = (state, props) => {
     return { propertyTypes: state?.search?.propertyTypes, ...props };
};

export const getDefaults = (props) => {
     const { searchState } = props;
     return ["A"];
};

export const hasChanged = (prev, curr) => {
     if (prev == null || curr == null) return false;

     const _prev = sortBy(prev).join(",");
     const _curr = sortBy(curr).join(",");

     return _prev != _curr;
};

export const getDisplay = (values, props = {}) => {
     const { propertyTypes } = props;

     if (values.length == 0) return "N/A";
     if (values.length == 4) return "All";

     let display = [];

     values.forEach((v) => {
          if (Array.isArray(v)) {
          } else {
               const match = propertyTypes.find((pt) => pt.value == v);
               display.push(values.length > 1 ? match.short : match.label);
          }
     });
     return display.join();
};

export const PropertyTypeCriteria = (props) => {
     const { classes: userClasses, criteria, onEnter, label } = props;

     const classes = styles();
     const criteriaState = useSelector(criteriaSelector.bind(null, criteria));
     const searchState = useSelector((s) => s.search);
     const propertyTypes = useSelector((s) => s.search.propertyTypes);
     const dispatch = useDispatch();

     // handlers
     const handleOnChange = (e) => {
          let newValues = cloneDeep(criteriaState.value);
          let index = findIndex(newValues, (v) => v == e.target.name);

          // add or remove this value
          if (index == -1) newValues.push(e.target.name);
          else newValues.splice(index, 1);

          e.stopPropagation();

          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: {
                    criteria: criteria,
                    value: newValues,
                    display: getDisplay(newValues, {
                         propertyTypes: propertyTypes,
                    }),
                    default: cloneDeep(criteriaState.default),
                    query: getQuery(newValues, {
                         propertyTypes: propertyTypes,
                    }),
               },
          });
     };

     // render
     if (criteriaState == null) return null;

     return (
          <div className={classes.root}>
               {propertyTypes.map((pt) => {
                    let name = Array.isArray(pt.value)
                         ? pt.value.join()
                         : pt.value;

                    return (
                         <FormControlLabel
                              control={
                                   <Checkbox
                                        checked={criteriaState.value.includes(
                                             pt.value
                                        )}
                                        onChange={handleOnChange}
                                        name={name}
                                        color="primary"
                                   />
                              }
                              label={pt.label}
                         />
                    );
               })}
          </div>
     );
};

export default PropertyTypeCriteria;
