import React, { useState, useEffect } from "react";

const If = (props) => {
     const { children, condition } = props;

     const render = condition instanceof Function ? condition() : condition;

     return render ? children : null;
};

export default If;
