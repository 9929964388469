import React from "react";

import { get } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import { memoize } from "lodash";

import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";

import { IsSmall, IsMedium, IsLargePlus } from "MediaQuery";

const styles = makeStyles({
     root: {
          objectFit: "contain",
          width: "100%",
          height: "100%",
     },
});

export const Logo = (props) => {
     const classes = styles();
     return <img className={classes.root} src="images/site-logo-default.png" />;
};

export default Logo;
