import React, { useState } from "react";

import {
     createMuiTheme,
     withStyles,
     makeStyles,
     ThemeProvider,
} from "@material-ui/core/styles";

import { Responsive, WidthProvider } from "react-grid-layout";

import Components from "Components/Components";

const ResponsiveGridLayout = WidthProvider(Responsive);

const layouts = {
     lg: [
          { i: "a", x: 0, y: 0, w: 4, h: 2 },
          { i: "b", x: 1, y: 0, w: 3, h: 2 },
          { i: "c", x: 4, y: 0, w: 1, h: 2 },
     ],
};

const styles = makeStyles({
     pageRoot: {
          backgroundImage: `url("/images/home-dark.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "rgba(1, 1, 1, 0.5)",
          backgroundPosition: "50% 50%",
          height: "100%",
     },
     gridRoot: {
          border: "1px solid red",
     },
     areaRoot: {
          //backgroundColor: "rgb(245,245,245)",
          border: "1px dashed black",
          overflow: "hidden",
          margin: 0,
          padding: 0,
     },
     areaContent: {
          pointerEvents: "none",
          width: "100%",
          height: "100%",
     },
});

const Page = (props) => {
     const classes = styles();

     return (
          <div className={classes.pageRoot}>
               <ResponsiveGridLayout
                    className={classes.gridRoot}
                    layouts={layouts}
                    breakpoints={{
                         lg: 1200,
                         md: 996,
                         sm: 768,
                         xs: 480,
                         xxs: 0,
                    }}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    containerPadding={[0, 0]}
                    rowHeight={50}
               >
                    <div className={classes.areaRoot} key="a">
                         <Area>
                              <Components.Logo />
                         </Area>
                    </div>
                    <div className={classes.areaRoot} key="b">
                         <Area>
                              <Components.Menu />
                         </Area>
                    </div>
                    <div className={classes.areaRoot} key="c">
                         Page Content
                    </div>
               </ResponsiveGridLayout>
          </div>
     );
};

const Area = (props) => {
     const { children, k } = props;
     const classes = styles();

     return (
          <div {...props} className={classes.areaContent}>
               {children}
          </div>
     );
};

export default Page;
