import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import {
     createMuiTheme,
     withStyles,
     makeStyles,
     ThemeProvider,
} from "@material-ui/core/styles";

import { Typography as Text, TextField, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandIcon from "@material-ui/icons/ExpandMore";

import { Link } from "react-router-dom";

import Style from "./Home.module.css";

import { IsSmall } from "MediaQuery";

import SearchBar from "Components/SearchBar";

const styles = makeStyles({});

const Home = (props) => {
     const isSmall = useMediaQuery(IsSmall);
     const history = useHistory();
     const searchCriteria = useSelector((s) => s.search.criteria);
     const dispatch = useDispatch();

     let classes = styles({ isSmall: isSmall });

     // event handlers
     const redirectSearch = () => {
          //history.push("/listings");
     };

     return (
          <div className={Style.root}>
               <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                         name="Show Me 417 Realty"
                         description="Homes for sale in Springfield, Ozark, Nixa, and surrounding areas of south-east Missouri."
                    />
                    <title>Show Me 417 Realty - Home</title>
                    <link
                         rel="canonical"
                         href="https://showme417realty.com/home"
                    />
                    <meta
                         name="viewport"
                         content="width=device-width, initial-scale=1"
                    />
               </Helmet>
               <SearchBar
                    hideCriteria={true}
                    hideMobileCriteria={true}
                    hideSort={true}
               />
          </div>
     );
};

export default Home;
