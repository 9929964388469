export const IsSmall = {
     query: "(max-width: 500px)",
};

export const IsMedium = {
     query: "(min-width: 501px) and (max-width: 1199px)",
};

export const IsMediumPlus = {
     query: "(min-width: 501px)",
};

export const IsLargePlus = {
     query: "(min-width: 1200px)",
};

export const IsExtraLargePlus = {
     query: "(min-width: 1800px)",
};

export const IsPortrait = {
     query: "(orientation: portrait)",
};

export const IsLandscape = {
     query: "(orientation: landscape)",
};
