import React, { useEffect, useRef } from "react";

export const ListingImage = (props) => {
     const { src, alt, loading, className } = props;

     const ref = useRef(null);

     const handleLoad = (e) => {
          if (ref.current.naturalHeight > ref.current.naturalWidth) {
               const ratio =
                    ref.current.naturalWidth / ref.current.naturalHeight;

               ref.current.style.width = `${Math.floor(
                    ref.current.naturalWidth * ratio
               )}px`;

               ref.current.style.height = `${Math.floor(
                    ref.current.naturalWidth
               )}px`;
          }
     };

     return (
          <img
               ref={ref}
               src={src}
               alt={alt}
               loading={loading}
               className={className}
               onLoad={handleLoad}
          />
     );
};

export default ListingImage;
