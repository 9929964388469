import React, { useState } from "react";

// misc

import cloneDeep from "lodash/cloneDeep";

// redux
import { useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     Button,
     ClickAwayListener,
     MenuList,
     MenuItem,
     Paper,
     Popper,
     Typography,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";

// icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// realty
import { DefaultState } from "RootReducer";

import { searchThunk } from "SearchUtility";

const styles = makeStyles({
     root: { display: "flex", /*padding: "16px",*/ marginTop: "8px" },
});

export const Sort = (props) => {
     const { usePopper = true, onSearch, searchOnChange = true } = props;

     const [state, setState] = useState({
          open: false,
          anchor: null,
          clickAwayActive: false,
     });

     const sortBy = useSelector((s) => s.search.sortBy);
     const sortByOptions = useSelector((s) => s.search.sortByOptions);

     const dispatch = useDispatch();

     const handleClick = (anchor) => {
          setState({
               open: !state.open,
               anchor: anchor.target,
               value: null,
               display: null,
          });
     };

     const handleClickAway = () => {
          if (!state.clickAwayActive) {
               setState({
                    open: state.open,
                    anchor: state.anchor,
                    clickAwayActive: true,
               });
          } else {
               handleClick(setState, null, false);
          }
     };

     const setSortAndSearch = (property, label, dispatch, getState) => {
          if (searchOnChange) {
               dispatch({
                    type: "SET_MANY",
                    data: [
                         {
                              path: "search.sortBy",
                              value: { property: property, label: label },
                         },
                         {
                              path: "search.paging",
                              value: cloneDeep(DefaultState.search.paging),
                         },
                    ],
               });

               searchThunk(dispatch, getState);
          } else {
               dispatch({
                    type: "SET_MANY",
                    data: [
                         {
                              path: "search.sortBy",
                              value: { property: property, label: label },
                         },
                    ],
               });
          }
     };

     const handleClickSort = (property, label, e) => {
          dispatch(setSortAndSearch.bind(null, property, label));
          handleClickAway();
     };

     const classes = styles();

     const label = sortBy?.label ? `Sort By: ${sortBy.label}` : "Sort By";

     const sortByMenu = sortByOptions.map((s, i) => (
          <MenuItem
               key={s.property}
               onClick={handleClickSort.bind(null, s.property, s.label)}
          >
               {s.label}
          </MenuItem>
     ));

     if (usePopper) {
          return (
               <>
                    <Button
                         onClick={handleClick}
                         //startIcon={<SortIcon />}
                         endIcon={<KeyboardArrowDownIcon />}
                    >
                         {label}
                    </Button>{" "}
                    <ClickAwayListener onClickAway={handleClickAway}>
                         <Popper
                              id={`sort_popper`}
                              open={state.open}
                              anchorEl={state.anchor}
                         >
                              <Paper
                                   classes={{
                                        root: classes.root,
                                   }}
                              >
                                   <MenuList>{sortByMenu}</MenuList>
                              </Paper>
                         </Popper>
                    </ClickAwayListener>
               </>
          );
     } else {
          return (
               <>
                    <Typography variant={"h6"}>{label}</Typography>
                    <MenuList>{sortByMenu}</MenuList>
               </>
          );
     }
};

export default Sort;
