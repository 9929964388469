import React, { useState, useEffect, useRef } from "react";
import * as ReactIs from "react-is";

// react router
import {
     Link,
     Switch,
     Route,
     useParams,
     useLocation,
     useHistory,
} from "react-router-dom";

// misc
import cloneDeep from "lodash/cloneDeep";
import { useMediaQuery } from "react-responsive";

// redux
import { useSelector, useDispatch } from "react-redux";

// material ui imports
import { Drawer } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

// realty
import ListingTile from "Components/ListingTile";
import NoResultsTile from "Components/NoResultsTile";
import SearchBar from "Components/SearchBar";
import ListingDetail from "Components/ListingDetail";

import RealtyService from "Services/RealtyService";

import { searchThunk } from "SearchUtility";

import {
     IsSmall,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

const gridResolver = (props) => {
     let { isSmall, isMedium, isLarge, isExtraLarge } = props;

     if (isExtraLarge) return "repeat(4, 1fr)";
     if (isLarge) return "repeat(3, 1fr)";
     if (isMedium) return "repeat(2, 1fr)";
     if (isSmall) return "1fr";

     return "repeat(3, 1fr)";
};

const styles = makeStyles({
     root: (props) => ({
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          //height: "10000px", // there's gotta be a better way :)
          backgroundColor: "rgba(255,255,255,1)",
     }),
     resultsRoot: (props) => ({
          flexGrow: 1,
          flexDirection: "column",

          display: props.resultsVisible ? "grid" : "none",
          gridTemplateColumns: gridResolver(props), //"1fr 1fr 1fr",

          // on mobile browsers, there seems to be an issue where the grid heights
          // don't calculate correctly so we get an extra gap so explicitely set
          // the height here
          gridAutoRows: "360px",

          columnGap: props.isSmall ? "0px" : "16px",
          rowGap: props.isSmall ? "8px" : "16px",

          margin: "0 16px 0 16px",
     }),
     tile: {
          margin: "0",
     },
});

export const SearchResults = (props) => {
     const { autoSearch = false, extraCriteria = [], onSearch } = props;

     const isSmall = useMediaQuery(IsSmall);
     const isMedium = useMediaQuery(IsMediumPlus);
     const isLarge = useMediaQuery(IsLargePlus);
     const isExtraLarge = useMediaQuery(IsExtraLargePlus);

     const dispatch = useDispatch();
     const { listingid } = useParams();
     const listings = useSelector((s) => s.search.listings);
     const activeListing = useSelector((s) => s.search.activeListing);
     const criteriaReady = useSelector((s) => s.search.criteriaReady);

     const classes = styles({
          isSmall,
          isMedium,
          isLarge,
          isExtraLarge,
          resultsVisible: activeListing == null,
     });

     // initialize all criteria

     useEffect(() => {
          dispatch({
               type: "INIT_ALL_SEARCH_CRITERIA",
               data: {
                    extraCriteria: cloneDeep(extraCriteria),
               },
          });
     }, []);

     const autoSearchThunk = (dispatch, getState) => {
          dispatch({
               type: "RESET_SORT",
          });

          dispatch({
               type: "RESET_PAGING",
          });

          dispatch({
               type: "SET",
               path: "search.autoSearch",
               value: true,
          });

          dispatch(searchThunk);
     };

     // used to automatically kick off a search IF the extra criteria have already
     // been set

     useEffect(() => {
          if (autoSearch && criteriaReady) dispatch(autoSearchThunk);

          //console.log("SearchResults.useEffect", autoSearch, criteriaReady);
     }, [autoSearch, criteriaReady]);

     // load data if there's a listing id set
     useEffect(() => {
          const loadData = async () => {
               // if listing is not set, this page could have been loaded from the url
               if (activeListing == null && listingid != null) {
                    let l = await RealtyService.getListingById(
                         listingid,
                         null,
                         null,
                         1
                    );

                    dispatch({
                         type: "SET",
                         path: "search.activeListing",
                         value: cloneDeep(l.Results[0]),
                    });
               }
          };

          loadData();
     }, [listingid]);

     const tileClasses = { root: classes.tile };

     return (
          <div className={classes.root}>
               <SearchBar hideCriteria={isSmall} hideSort={isSmall} />
               <div id="search_results_root" className={classes.resultsRoot}>
                    {listings.map((l, index) => {
                         return l ? (
                              <Link to={`/search/${l.Id}`} key={l.Id}>
                                   <ListingTile listing={l} index={index} />
                              </Link>
                         ) : (
                              <ListingTile
                                   listing={l}
                                   key={index}
                                   index={index}
                              />
                         );
                    })}
                    {listings.length == 0 ? <NoResultsTile /> : null}
               </div>
               {activeListing ? <ListingDetail /> : null}
          </div>
     );
};

export default SearchResults;
