import React, { useCallback, useState, useEffect, useRef } from "react";
import * as ReactIs from "react-is";

// misc
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { useMediaQuery } from "react-responsive";

// slideshow
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

// material ui imports
import {
     Accordion,
     AccordionSummary,
     AccordionDetails,
     Divider,
     Drawer,
     Typography,
     IconButton,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { IsSmall, IsMediumPlus, IsLargePlus, IsLandscape } from "MediaQuery";

// realty
import RealtyService from "Services/RealtyService";

import If from "Components/If";
import ListingImage from "Components/ListingImage";

const styles = makeStyles({
     // containers / areas
     root: (props) => ({
          background: "white",
          height: "100%",
          display: "flex",
          flexDirection: props.isSmall ? "column" : "row",
     }),
     leftColumn: (props) => ({
          order: props.isSmall ? 2 : 1,
          flexGrow: 1,
     }),
     rightColumn: (props) => ({
          order: 3,
          flexGrow: 1,
     }),
     middleColumn: (props) => ({
          padding: "0 8px 0 8px",
          minWidth: props.isSmall ? "95%" : "720px",
          maxWidth: props.isSmall ? "95%" : "1024px",
          //width: "1024px",
          //height: "700px",
          order: props.isSmall ? 1 : 2,
     }),
     toolbar: {
          display: "flex",
          width: "100%",
     },
     drawerRoot: {
          margin: "0px auto 0px auto",
          display: "flex",
          flexDirection: "column",
     },
     listing: {
          margin: "0px auto 0px auto",
     },

     detailsContainer: {
          flexDirection: "column",
     },

     spacer: {
          margin: "16px 0px 0px 0px",
     },
     schools: {
          display: "grid",
          gridTemplateColumns: "150px auto",
          gridTemplateRows: "auto auto auto",
          gridAutoFlow: "row",
     },

     propertyDetailsOuter: {
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridTemplateRows: "auto",
          gridAutoFlow: "column",
     },
     propertyDetailsInner: {
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridTemplateRows: "auto auto",
          gridAutoFlow: "row",
     },
     priceContainer: {
          display: "flex",
          alignItems: "baseline",
          margin: "8px 0px 0px 0px",
     },

     // icons / buttons
     icon: {
          fontSize: 48,
     },
     galleryIcon: {
          fontSize: 48,
          color: "white",
     },
     prev: {
          transform: "rotate(180deg)",
     },
     next: {
          margin: "0px 0px 0px auto",
     },
     photoIcon: {
          fontSize: 20,
     },
});

export const ListingDetail = (props) => {
     // props
     const { classes: userClasses, onClose } = props;

     // hooks
     const [images, setImages] = useState(null);
     const [photoIndex, setPhotoIndex] = useState(0);

     const isSmall = useMediaQuery(IsSmall);
     const isMediumPlus = useMediaQuery(IsMediumPlus);
     const isLargePlus = useMediaQuery(IsLargePlus);
     const isLandscape = useMediaQuery(IsLandscape);
     const _window = useSelector((s) => s.window);
     const galleryDims = useSelector((s) => s.dimensions.gallery);
     const listing = useSelector((s) => s.search.activeListing);
     const dispatch = useDispatch();

     const classes = styles({
          isSmall: isSmall,
          isMediumPlus: isMediumPlus,
          isLargePlus: isLargePlus,
          window: _window,
          galleryDims: galleryDims,
     });

     const { listingid } = useParams();

     useEffect(() => {
          const loadData = async () => {
               // if listing is not set, this page could have been loaded from the url
               if (listing == null) {
                    let l = await RealtyService.getListingById(
                         listingid,
                         null,
                         null,
                         1
                    );

                    dispatch({
                         type: "SET",
                         path: "search.activeListing",
                         value: cloneDeep(l.results[0]),
                    });
               }

               // get photos
               const results = await RealtyService.getListingPhotos(listingid);

               // build images
               let newImages = results.Results.map((p, i) => {
                    return {
                         photoSm: p.Uri640,
                         photoMd: p.Uri800,
                         photoLg: p.Uri1024,
                         photoXl: p.Uri1280,
                         thumbnail: p.UriThumb,
                    };
               });

               setImages(newImages);
          };

          loadData();
     }, []);

     // render props
     const fields = get(listing, "StandardFields", {});
     const userRoot = get(userClasses, "root", "");
     const root = `${classes.root} ${userRoot}`;

     // calculate / build strings
     const price = fields.ListPrice
          ? fields.ListPrice.toLocaleString("en-US", {
                 style: "currency",
                 currency: "USD",
                 minimumFractionDigits: 0,
                 maximumFractionDigits: 0,
            })
          : null;

     let pricePerArea = null;
     let pricePerAreaStr = null;

     switch (fields.PropertyType) {
          case "A":
               if (
                    fields.BuildingAreaTotal != null &&
                    fields.BuildingAreaTotal != 0
               ) {
                    pricePerArea = (
                         fields.ListPrice / fields.BuildingAreaTotal
                    ).toLocaleString("en-US", {
                         style: "currency",
                         currency: "USD",
                         minimumFractionDigits: 0,
                         maximumFractionDigits: 0,
                    });

                    pricePerAreaStr = `${pricePerArea} / sqft`;
               }
               break;
          case "C":
               if (fields.LotSizeArea != null && fields.LotSizeArea != 0) {
                    pricePerArea = (
                         fields.ListPrice / fields.LotSizeArea
                    ).toLocaleString("en-US", {
                         style: "currency",
                         currency: "USD",
                         minimumFractionDigits: 0,
                         maximumFractionDigits: 0,
                    });

                    if (fields.LotSizeUnits == "Acres")
                         pricePerAreaStr = `${pricePerArea} / acre`;
               }
               break;
     }

     const prefix = fields.StreetDirPrefix;
     let prefixLower = prefix;
     switch (prefixLower) {
          case "north":
          case "south":
          case "east":
          case "west":
               prefix = prefix[0];
     }

     const address = `${fields.StreetNumber} ${prefix ?? ""} ${
          fields.StreetName
     } ${fields.StreetDirSuffix ?? ""} - ${fields.City}, ${
          fields.StateOrProvince
     }, ${fields.PostalCode}`.trim();

     let daysOnMarketStr = null;
     if (fields.OnMarketDate == null) daysOnMarketStr = "n/a";
     else {
          const diff = new Date() - new Date(fields.OnMarketDate);
          const daysOnMarket = Math.ceil(diff / (1000 * 3600 * 24));
          daysOnMarketStr = `${daysOnMarket} days`;
     }

     // start generating markup

     //if (images == null) return null;

     const BodyText = (props) => (
          <Typography variant="body1" {...props}>
               {props.children}
          </Typography>
     );
     const SmallText = (props) => (
          <Typography variant="body2" {...props}>
               {props.children}
          </Typography>
     );
     const HeaderText = (props) => (
          <Typography variant="h5" {...props}>
               {props.children}
          </Typography>
     );
     const Header = (props) => (
          <div className={classes.header}>
               <Divider />
               <HeaderText>{props.children}</HeaderText>
               <Divider />
          </div>
     );

     const Spacer = () => {
          return <div className={classes.spacer}>&nbsp;</div>;
     };

     const _AccordianSummary = (props) => {
          return (
               <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props}>
                    {props.children}
               </AccordionSummary>
          );
     };

     // override the buttons used in the Slider
     const buttonStyle = {
          position: "relative",
     };

     const slideButtonProps = {
          prevArrow: (
               <button style={{ ...buttonStyle }}>
                    <ArrowBackIosIcon />
               </button>
          ),
          nextArrow: (
               <button style={{ ...buttonStyle }}>
                    <ArrowForwardIosIcon />
               </button>
          ),
     };

     return (
          <div className={classes.root}>
               <div className={classes.leftColumn}></div>
               <div id="col2" className={classes.middleColumn}>
                    <div className={classes.listing}>
                         <div className={classes.detailsContainer}>
                              <div className={classes.priceContainer}>
                                   <HeaderText>{price}</HeaderText>
                                   {pricePerArea ? (
                                        <SmallText>
                                             &nbsp;{pricePerAreaStr}
                                        </SmallText>
                                   ) : null}
                              </div>

                              <div>
                                   <BodyText>
                                        <If
                                             condition={
                                                  fields.PropertyType == "A"
                                             }
                                        >
                                             <strong>{fields.BedsTotal}</strong>{" "}
                                             bed{" "}
                                             <strong>
                                                  {fields.BathsTotal}
                                             </strong>{" "}
                                             bath{" "}
                                             <strong>
                                                  {Math.trunc(
                                                       fields.BuildingAreaTotal
                                                  )}
                                             </strong>{" "}
                                             sq ft&nbsp;
                                        </If>
                                        <strong>
                                             {fields.LotSizeArea}{" "}
                                             {fields.LotSizeUnits}
                                        </strong>
                                   </BodyText>
                                   <BodyText>{address}</BodyText>
                              </div>
                         </div>

                         <Carousel
                              showArrows={true}
                              dynamicHeight={false}
                              showIndicators={!isSmall}
                              preventMovementUntilSwipeScrollTolerance={true}
                              wipeScrollTolerance={50}
                         >
                              {images?.map((image, index) => (
                                   <ListingImage
                                        key={index}
                                        src={
                                             isSmall
                                                  ? image.photoSm
                                                  : image.photoXl
                                        }
                                        loading="lazy"
                                        className={classes.image}
                                   />
                              ))}
                         </Carousel>
                         <Accordion defaultExpanded={true}>
                              <_AccordianSummary id="details">
                                   <HeaderText>Property Details</HeaderText>
                              </_AccordianSummary>
                              <AccordionDetails>
                                   <div className={classes.detailsContainer}>
                                        <div
                                             className={`${classes.section} ${classes.propertyDetailsOuter}`}
                                        >
                                             <div
                                                  className={`${classes.propertyDetailsInner}`}
                                             >
                                                  <BodyText>
                                                       Property Type
                                                  </BodyText>
                                                  <BodyText>
                                                       {fields.PropertySubType}
                                                  </BodyText>
                                                  <BodyText>Status</BodyText>
                                                  <BodyText>
                                                       {fields.StandardStatus}
                                                  </BodyText>
                                             </div>

                                             <div
                                                  className={`${classes.propertyDetailsInner}`}
                                             >
                                                  <BodyText>
                                                       Days on Market
                                                  </BodyText>
                                                  <BodyText>
                                                       {daysOnMarketStr}
                                                  </BodyText>
                                                  <BodyText>
                                                       Year Built
                                                  </BodyText>
                                                  <BodyText>
                                                       {fields.YearBuilt}
                                                  </BodyText>
                                             </div>
                                        </div>

                                        <Spacer />
                                        <BodyText>
                                             {fields.PublicRemarks}
                                        </BodyText>
                                   </div>
                              </AccordionDetails>
                         </Accordion>

                         <Accordion defaultExpanded={true}>
                              <_AccordianSummary id="schools">
                                   <HeaderText>Schools</HeaderText>
                                   <Divider />
                              </_AccordianSummary>
                              <AccordionDetails>
                                   <div className={`${classes.schools}`}>
                                        <BodyText>Elementary School</BodyText>
                                        <BodyText>
                                             {fields.ElementarySchool}
                                        </BodyText>
                                        <BodyText>Middle School</BodyText>
                                        <BodyText>
                                             {fields.MiddleOrJuniorSchool}
                                        </BodyText>
                                        <BodyText>High School</BodyText>
                                        <BodyText>{fields.HighSchool}</BodyText>
                                   </div>
                              </AccordionDetails>
                         </Accordion>
                         <Spacer />
                         <Typography classes={{ root: classes.listingAgent }}>
                              Listing: <strong>{fields.ListAgentName}</strong>{" "}
                              with <strong>{fields.ListOfficeName}</strong>
                         </Typography>
                         <Spacer />
                    </div>
               </div>
               <div className={classes.rightColumn}></div>
          </div>
     );
};

export default ListingDetail;
