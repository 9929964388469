export const criteriaSelector = (state) => {
     return null;
};

export const getDefaults = (props) => {
     return null;
};

export const getQuery = (value) => {
     if (value == null) return "";
     else return `(ListAgentId Eq '${value}')`;
};

export const create = (value, props) => {
     return {
          criteria: "ListAgent",
          value: value,
          default: getDefaults(),
          display: null,
          query: getQuery(value),
     };
};
