import React, {
     cloneElement,
     isValidElement,
     useState,
     useEffect,
} from "react";

import { searchThunk } from "SearchUtility";

// misc
import { get, findIndex } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     Button,
     ClickAwayListener,
     Drawer,
     Dialog,
     DialogContent,
     DialogTitle,
     IconButton,
     AppBar,
     Toolbar,
     Paper,
     Popper,
     Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";

import {
     IsSmall,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

import RealtyService from "Services/RealtyService";

const styles = makeStyles({
     root: (props) => ({
          display: "flex",
          whiteSpace: "nowrap",
          overflow: "hidden",
     }),
     label: {
          textOverflow: "ellipsis",
          maxWidth: "150px",
     },
     criteriaDisplay: {
          marginTop: "16px",
          padding: "16px",
     },
     criteriaOptionsRoot: {
          display: "flex",
          marginTop: "4px",
          padding: "8px",
     },
     criteriaOptionsDrawerRoot: {
          display: "flex",
          padding: "16px",
          //width: "calc(100% - 24px)",
     },
     criteriaDrawContent: {
          display: "flex",
          alignItems: "center",
          //width: "calc(100% - 24px)",
     },
     dialogContent: {
          backgroundColor: "white",
     },
});

const handleClick = (setState, anchor, open) => {
     setState({
          open: open,
          anchor: anchor,
          value: null,
          display: null,
     });
};

const addPropsToChildren = (children, props) => {
     const childrenWithProps = Array.isArray(children)
          ? children.map((c) => {
                 if (isValidElement(c)) return cloneElement(c, props);

                 return children;
            })
          : isValidElement(children)
          ? cloneElement(children, props)
          : children;

     return childrenWithProps;
};

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

/*

todo: we need different switchable render modes, one for the criteria when it's active vs inactive

*/

export const SearchCriteria = (props) => {
     const {
          classes: userClasses,
          renderChild = false,
          criteria,
          label,
          children,
          usePopper = true,
          getDefaults,
          hasChanged = null,
          modal = false,
          showLabel = true,
          searchOnChange = true,
     } = props;

     const [state, setState] = useState({
          open: false,
          anchor: null,
          clickAwayActive: false,
     });

     const [initialValue, setInitialValue] = useState(null);

     const criteriaState = useSelector(criteriaSelector.bind(null, criteria));
     const searchState = useSelector((s) => s.search);
     const dispatch = useDispatch();

     // automatically search when values change and

     useEffect(() => {
          // when we close, dispatch a search
          // (searchThunk will check if the query has actually changed)
          if (
               state.open === false &&
               searchState.searching === false &&
               searchState.hasSearched === true
          )
               dispatch(searchThunk);
     }, [state.open, searchState.searching, searchState.hasSearched]);

     const isSmall = useMediaQuery(IsSmall);
     const isMedium = useMediaQuery(IsMediumPlus);
     const isLarge = useMediaQuery(IsLargePlus);
     const isExtraLarge = useMediaQuery(IsExtraLargePlus);

     const classes = styles({
          isSmall: isSmall,
          isMedium: isMedium,
          lg: isLarge,
          xl: isExtraLarge,
     });

     const userRootClass = get(userClasses, "root", "");
     const rootClass = `${classes.root} ${userRootClass} `;

     const handleClickAway = () => {
          if (!state.clickAwayActive) {
               setState({
                    open: state.open,
                    anchor: state.anchor,
                    clickAwayActive: true,
               });
          } else {
               handleClick(setState, null, false);
          }
     };

     const handleCloseModal = () => {
          setState({
               open: false,
               anchor: state.anchor,
               clickAwayActive: state.clickAwayActive,
          });
     };

     const resetAndSearchThunk = (dispatch, getState) => {
          dispatch({
               type: "RESET_SEARCH_CRITERIA",
               data: {
                    criteria: criteria,
               },
          });

          dispatch(searchThunk);
     };

     const handleCancel = (e) => {
          e.stopPropagation();
          dispatch(resetAndSearchThunk);
     };

     const displayLabel = get(criteriaState, "display", null);
     const buttonLabel = !state.open && displayLabel ? displayLabel : label;

     const endIcon =
          !state.open && displayLabel ? (
               <CancelIcon onClick={handleCancel} />
          ) : (
               <KeyboardArrowDownIcon />
          );

     const childrenWithProps = addPropsToChildren(children, {
          criteria: criteria,
          onEnter: handleClickAway,
          label: label,
          ...props,
     });

     if (renderChild) return childrenWithProps;

     return usePopper ? (
          <>
               <Button
                    variant="outlined"
                    endIcon={endIcon}
                    classes={{ root: rootClass, label: classes.label }}
                    onClick={(e) =>
                         handleClick(setState, e.currentTarget, !state.open)
                    }
               >
                    {buttonLabel}
               </Button>

               {modal ? (
                    <Drawer
                         open={state.open}
                         anchor={"top"}
                         onClose={handleCloseModal}
                    >
                         <Paper
                              classes={{
                                   root: classes.criteriaOptionsDrawerRoot,
                              }}
                         >
                              <div className={classes.criteriaDrawContent}>
                                   {showLabel ? (
                                        <Typography variant={"h6"}>
                                             {label}
                                        </Typography>
                                   ) : null}
                                   {childrenWithProps}
                              </div>
                         </Paper>
                    </Drawer>
               ) : (
                    <ClickAwayListener onClickAway={handleClickAway}>
                         <Popper
                              id={`${criteria}_popper`}
                              open={state.open}
                              anchorEl={state.anchor}
                         >
                              <Paper
                                   classes={{
                                        root: classes.criteriaOptionsRoot,
                                   }}
                              >
                                   {childrenWithProps}
                              </Paper>
                         </Popper>
                    </ClickAwayListener>
               )}
          </>
     ) : (
          <>
               {showLabel ? (
                    <Typography variant={"h6"}>{label}</Typography>
               ) : null}
               {childrenWithProps}
          </>
     );
};

export default SearchCriteria;
