import React, { useEffect } from "react";
import * as ReactIs from "react-is";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";

import { Link } from "react-router-dom";

// misc
import { useMediaQuery } from "react-responsive";
import {
     IsSmall,
     IsMedium,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const styles = makeStyles({
     root: (props) => ({
          margin: "0px 12px 0px 0px",
     }),
     input: (props) => ({
          fontSize: props.isSmall ? "8px !important" : null,
     }),
});

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

export const create = (criteriaName, value) => {
     return {
          criteria: criteriaName,
          value: null,
          default: null,
     };
};

export const getDefaults = (props) => {
     return "";
};

export const TextCriteria = (props) => {
     const {
          classes: userClasses,
          criteria,
          onKeyPress,
          label,
          type = "string",
     } = props;

     const isSmall = useMediaQuery(IsSmall);
     const isMedium = useMediaQuery(IsMedium);
     const isMediumPlus = useMediaQuery(IsMediumPlus);
     const classes = styles({ isSmall });

     // build styles that could be overriden
     const userClassRoot = get(userClasses, "root", "");
     const rootClass = `${classes.root} ${userClassRoot}`;

     const criteriaState = useSelector(criteriaSelector.bind(null, criteria));
     const propertyTypes = useSelector((s) => s.propertyTypes);
     const dispatch = useDispatch();

     // handlers
     const getDisplay = (value) => {
          return value;
     };

     const getQuery = (value) => {
          if (value.trim().length == 0) return null;

          return type == "string"
               ? `${criteria} Eq '${value}'`
               : `${criteria} Eq ${value}`;
     };

     const parseValue = (value) => {
          switch (type) {
               case "string":
                    return value;
               case "int":
                    return Number.parseInt(value);
               case "float":
                    return Number.parseFloat(value);
          }
     };

     const handleChange = (e) => {
          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: {
                    criteria: criteria,
                    value: parseValue(e.target.value),
                    default: null,
                    query: getQuery(e.target.value),
               },
          });
     };

     let value = get(criteriaState, "value", null);

     return (
          <TextField
               id={criteria}
               label=""
               placeholder={label}
               onChange={handleChange}
               onKeyPress={onKeyPress}
               autoComplete="off"
               value={value}
               classes={{ root: rootClass }}
               inputProps={{ style: { fontSize: "16px" } }}
          />
     );
};

export default TextCriteria;
