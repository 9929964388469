import React from "react";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	withRouter,
} from "react-router-dom";

import { get } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import { memoize } from "lodash";

import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";

import { IsSmall, IsMedium, IsLargePlus } from "MediaQuery";

const styles = makeStyles({
	root: (props) => ({
		order: props.isSmall || props.isMedium ? 2 : null,
		margin: props.isSmall || props.isMedium ? "auto" : "auto auto auto 0",
	}),
});

export const SiteLogo = (props) => {
	const isSmall = useMediaQuery(IsSmall);
	const isMedium = useMediaQuery(IsMedium);
	const isLargePlus = useMediaQuery(IsLargePlus);

	const classes = styles({
		isSmall: isSmall,
		isMedium: isMedium,
		path: props.location.pathname,
	});

	const tenant = useSelector((state) => {
		return {
			customStyles: get(state, "tenant.customStyles.logo", null),
			logos: get(state, "tenant.logo"),
		};
	});

	// TODO: figure out a better way

	// customStyles can be an object OR a function
	const logoStyleProps = {
		isSmall: isSmall,
		isMedium: isMedium,
		isLargePlus: isLargePlus,
	};

	let evaluatedCustomStyles =
		typeof tenant.customStyles == "function"
			? tenant.customStyles(logoStyleProps)
			: tenant.customStyles;

	let customClasses = evaluatedCustomStyles
		? makeStyles(evaluatedCustomStyles)()
		: {};

	const { classes: userClasses } = props;

	const userLogoRoot = get(userClasses, "root", "");
	const logoRoot = `${classes.root} ${userLogoRoot} ${customClasses.root}`;

	let logo = null;

	if (isSmall) logo = tenant.logos.small;
	if (isMedium) logo = tenant.logos.medium;
	if (isLargePlus) logo = tenant.logos.large;

	return (
		<Switch>
			<Route path="/" exact>
				<img src={logo} className={logoRoot} />
			</Route>
			<Route path="*">
				<img src={tenant.logos.medium} className={logoRoot} />
			</Route>
		</Switch>
	);
};

export default withRouter(SiteLogo);
