import React, { useState, useCallback, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { IsSmall } from "MediaQuery";
import { useMediaQuery } from "react-responsive";

// material ui imports
import { Link, Typography, Typography as Text } from "@material-ui/core";

const styles = makeStyles({
     root: (props) => ({
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          height: "100%",
          backgroundColor: "rgba(255,255,255,1)",
          overflow: "hidden",
     }),
     heroContainer: {
          overflow: "hidden",
          //border: "1px solid red",
          backgroundImage: "url(/images/about-hero-1080.jpg)",
          backgroundPosition: "center center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
     },
     heroText: (props) => ({
          margin: "auto",
          textShadow: "1px 1px 2px rgba(150, 150, 150, 1)",
          color: props.theme.palette.common.white,
          //fontSize: "4em",
          //fontWeight: "100",
     }),
     body: (props) => ({
          //background: props.theme.palette.common.white,
          height: props.isSmall ? "100%" : "50%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "rgba(255,255,255,0.85)",
     }),
     text: (props) => ({
          margin: "auto",
          width: props.isSmall ? "90%" : "50%",
          display: "flex",
          flexDirection: "column",
     }),
});

const About = (props) => {
     const isSmall = useMediaQuery(IsSmall);
     const classes = styles({ theme: window.theme, isSmall });

     return (
          <div className={classes.root}>
               <div className={classes.heroContainer}>
                    {!isSmall ? (
                         <Typography
                              variant="h1"
                              classes={{ root: classes.heroText }}
                         >
                              About
                         </Typography>
                    ) : null}
                    <div className={classes.body}>
                         <div className={classes.text}>
                              {isSmall ? (
                                   <Typography
                                        variant="h5"
                                        //classes={{ root: classes.heroText }}
                                   >
                                        About ShowMe 417 Realty
                                   </Typography>
                              ) : null}
                              <Typography
                                   variant="body1"
                                   style={{ margin: "16px 0 0 0" }}
                              >
                                   Show Me 417 Realty was founded on the premise
                                   that if we help others achieve their goals,
                                   then we will achieve ours. That goal is to
                                   provide first class representation for buyers
                                   and sellers of real estate in the 417 area
                                   code of the Ozarks in Southwest Missouri.
                              </Typography>
                              <Typography
                                   variant="body1"
                                   style={{ marginTop: "12px" }}
                              >
                                   Whether it's by boat or car, if you want to
                                   see it, we're ready to show it to you. From
                                   the tri-lakes of Branson to the Springfield
                                   Plateau. From Pomme De Terre Lake to Fellows
                                   Lake. From our famous winding floating rivers
                                   and bluffs, to our gentle rolling hills.
                                   Whatever it is you want to see, we want to
                                   show it to you.
                              </Typography>

                              <Typography
                                   variant="h5"
                                   style={{ margin: "16px 0 0 auto" }}
                              >
                                   Tony Conlan
                              </Typography>
                              <Typography
                                   variant="body1"
                                   style={{ margin: "0 0 0 auto" }}
                              >
                                   Broker-Associate
                              </Typography>
                              <Typography
                                   variant="body1"
                                   style={{ margin: "0 0 0 auto" }}
                              >
                                   <Link href="mailto:tony@showme417realty.com">
                                        tony@showme417realty.com
                                   </Link>
                              </Typography>
                              <Typography
                                   variant="body1"
                                   style={{ margin: "0 0 0 auto" }}
                              >
                                   <Link href="tel:4176191171">
                                        (417) 619-1171)
                                   </Link>
                              </Typography>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default About;
