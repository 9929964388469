import React from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { set, findIndex, cloneDeep } from "lodash";

import CriteriaExports from "Components/SearchCriteria/CriteriaExports";
import { CompareSharp } from "@material-ui/icons";

import { buildQuery } from "SearchUtility";

const sortByOptions = [
     { property: "-OnMarketDate", label: "Newest Listings" },
     { property: "+ListPrice", label: "Lowest Price" },
     { property: "-ListPrice", label: "Highest Price" },
];

export const DefaultState = {
     window: {
          innerWidth: null,
          innerHeight: null,
     },
     menuOpen: false,
     prevSearch: {
          query: null,
          paging: {
               currentPage: 0,
               resultsPerPage: 20,
               totalPages: 100,
               showNext: 5,
          },
          sortBy: sortByOptions[0],
     },
     search: {
          autoSearch: false,
          searching: false,
          extraCriteriaSet: false,
          hasSearched: false,
          //ready: false,
          listings: [],
          //listingsLoading: false,
          activeListing: null,
          paging: {
               currentPage: 0,
               resultsPerPage: 20,
               totalPages: null,
               showNext: 5,
          },
          sortBy: sortByOptions[0],
          sortByOptions: sortByOptions,
          criteria: [],
          criteriaReady: false,
          // per user data
          propertyTypes: [
               {
                    label: "Residential",
                    value: "A",
                    short: "Res",
               },
               {
                    label: "Land",
                    value: "C",
                    short: "Land",
               },
               {
                    label: "Farm",
                    value: "D",
                    short: "Farm",
               },
               {
                    label: "Commercial",
                    value: "E",
                    short: "Comm",
               },
          ],
          bedsTotalRange: [1, 2, 3],
          bathsTotalRange: [1, 2, 3],
          waterfrontOptions: [], // loaded once via API
     },
     dimensions: {},
     // specific to each tennant
     tenant: {
          logo: {
               small: "/images/site-logo-mobile.png",
               medium: "/images/site-logo-mobile.png",
               large: "/images/site-logo-default.png",
               extraLarge: "/images/site-logo-default.png",
          },
          menuItems: [
               { text: "home", path: "/" },
               "about",
               "featured listings",
               "waterfront homes",
               "luxury homes",
               "land listings",
               //{ text: "login", icon: <LockIcon /> },
          ],
          customStyles: {
               logo: (props) => {
                    return {
                         root: {
                              /*
						//width: props.isMobile"240px",
						//height: "120px",
						margin:
							props.isSmall || props.isMedium
								? "16px auto auto auto"
								: "16px 0 0 16px",
								*/
                         },
                    };
               },
          },
     },
};

const RootReducer = (state, action) => {
     let newState = cloneDeep(state);

     switch (action.type) {
          case "RESET_ALL_SEARCH_CRITERIA":
          case "INIT_ALL_SEARCH_CRITERIA":
               //if (newState.search.criteria.length == 0) {
               for (let c = 0; c < CriteriaExports.Array.length; c++) {
                    let criteriaName =
                         CriteriaExports.Array[c].componentProps.criteria;

                    // does this criteria alreay exist in state?
                    let i = findIndex(newState.search.criteria, {
                         criteria: criteriaName,
                    });

                    const criteriaPropsSelector =
                         CriteriaExports.Array[c].criteriaPropsSelector;

                    const componentProps =
                         CriteriaExports.Array[c].componentProps;

                    // prettier-ignore
                    const criteriaProps = criteriaPropsSelector != null
                         ? CriteriaExports.Array[c].criteriaPropsSelector(newState, componentProps)
                         : null;

                    // insert
                    if (i == -1) {
                         newState.search.criteria.push(
                              CriteriaExports.Array[c].create(
                                   CriteriaExports.Array[c].getDefaults(
                                        criteriaProps
                                   ),
                                   { ...criteriaProps, ...componentProps }
                              )
                         );

                         i = newState.search.criteria.length - 1;
                    } else if (action.type == "RESET_ALL_SEARCH_CRITERIA") {
                         const canReset =
                              action.exclude == null
                                   ? true
                                   : !action.exclude.includes(criteriaName);

                         if (canReset) {
                              // prettier-ignore
                              newState.search.criteria[i] = CriteriaExports.Array[c].create(
                                   CriteriaExports.Array[c].getDefaults(criteriaProps),
                                   { ...criteriaProps, ...componentProps }
                              );
                         }
                    }
               }

               // now set any extra criteria
               if (action?.data?.extraCriteria) {
                    for (let c = 0; c < action.data.extraCriteria.length; c++) {
                         const i = findIndex(newState.search.criteria, {
                              criteria: action.data.extraCriteria[c].criteria,
                         });

                         if (i == -1)
                              newState.search.criteria.push(
                                   action.data.extraCriteria[c]
                              );
                         else
                              newState.search.criteria[i] =
                                   action.data.extraCriteria[c];
                    }
               }

               //if (action.type == "RESET_ALL_SEARCH_CRITERIA")
               newState.search.query = buildQuery(newState.search.criteria);

               newState.search.criteriaReady = true;
               break;

          case "SET":
               set(newState, action.path, action.value);
               break;

          case "SET_MANY":
               action.data.forEach((d) => {
                    set(newState, d.path, d.value);
               });

               break;

          case "SET_PREV_SEARCH":
               newState.prevSearch = cloneDeep(newState.search);
               break;

          case "SET_SEARCH_CRITERIA":
               const i = findIndex(newState.search.criteria, {
                    criteria: action.data.criteria,
               });
               if (i == -1) newState.search.criteria.push(action.data);
               else newState.search.criteria[i] = action.data;

               // we always update the current/next query, but only update prevQuery when we actually initiate a search
               newState.search.query = buildQuery(newState.search.criteria);

               break;

          case "SET_EXTRA_SEARCH_CRITERIA":
          case "SET_MULTIPLE_SEARCH_CRITERIA":
               for (let c = 0; c < action.data.length; c++) {
                    const i = findIndex(newState.search.criteria, {
                         criteria: action.data[c].criteria,
                    });

                    if (i == -1) newState.search.criteria.push(action.data[c]);
                    else newState.search.criteria[i] = action.data[c];
               }

               // we always update the current/next query, but only update prevQuery when we actually initiate a search
               newState.search.query = buildQuery(newState.search.criteria);

               if (action.type == "SET_EXTRA_SEARCH_CRITERIA")
                    newState.search.extraCriteriaSet = true;

               break;

          case "RESET_SEARCH_CRITERIA":
               const c = findIndex(newState.search.criteria, {
                    criteria: action.data.criteria,
               });

               newState.search.criteria[c].value = cloneDeep(
                    newState.search.criteria[c].default
               );

               // check out CriteriaExports for this so we can run
               // our getQuery func to generate a default query
               const criteriaExport =
                    CriteriaExports[newState.search.criteria[c].criteria];

               const criteriaProps = criteriaExport?.criteriaPropsSelector
                    ? criteriaExport.criteriaPropsSelector(newState)
                    : null;

               const componentProps = CriteriaExports.Array[c].componentProps;

               // update query
               newState.search.criteria[c].query = criteriaExport?.getQuery
                    ? criteriaExport.getQuery(
                           newState.search.criteria[c].value,
                           // combine the criteriaSelectorProps and any defined componentProps
                           { ...criteriaProps, ...componentProps }
                      )
                    : null;

               // update display
               newState.search.criteria[c].display = criteriaExport?.getDisplay
                    ? criteriaExport.getDisplay(
                           newState.search.criteria[c].value,
                           { ...criteriaProps, ...componentProps }
                      )
                    : null;

               // we always update the current/next query, but only update prevQuery when we actually initiate a search
               newState.search.query = buildQuery(newState.search.criteria);

               break;

          case "RESET_SORT":
               newState.search.sortBy = cloneDeep(DefaultState.search.sortBy);
               break;

          case "RESET_PAGING":
               newState.search.paging = cloneDeep(DefaultState.search.paging);
               break;
     }

     if (process.env.NODE_ENV == "development")
          console.log(
               `STATE: ${action.type}`,
               action,
               newState.search.criteria?.length,
               newState.search.criteria
          );

     return newState;
};

export const Store = createStore(
     RootReducer,
     DefaultState,
     applyMiddleware(thunk)
);
