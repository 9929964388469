import React, { useState, useCallback, useEffect } from "react";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// misc packages
import { useMediaQuery } from "react-responsive";
import {
     BrowserRouter as Router,
     Switch,
     Route,
     useLocation,
} from "react-router-dom";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// material ui imports
import { Dialog, DialogContent } from "@material-ui/core";

import {
     makeStyles,
     useTheme,
     ThemeProvider,
     createMuiTheme,
} from "@material-ui/core/styles";

// Custom views / components
import Home from "./Home/Home";
import SearchResults from "./SearchResults";
import ListingDetail from "Components/ListingDetail";
import Header from "../Components/Header";
import Page from "../Components/Page";
import About from "./About/About";

import { create as createPrice } from "Components/SearchCriteria/PriceCriteria";
import { create as createWater } from "Components/SearchCriteria/WaterCriteria";
import { create as createPropertyType } from "Components/SearchCriteria/PropertyTypeCriteria";
import { create as createListAgent } from "Components/SearchCriteria/ListAgentCriteria";

const styles = makeStyles({
     /*
     root: {
          width: "100%",
          height: "100%",
          backgroundColor: "black",
     },
     */
     content: {
          display: "flex",
          flexDirection: "column",

          overflowX: "hidden",
          position: "absolute",
          width: "100%",
          height: "100%",

          backgroundImage: `url("/images/home-dark.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "rgba(1, 1, 1, 0.5)",
          backgroundPosition: "50% 50%",
     },
});

export const searchFunc = async (searchFunc, query, sortBy, page = 0) => {
     const listings = await searchFunc(
          query,
          null,
          "PrimaryPhoto",
          sortBy?.property ?? null,
          20,
          page + 1
     );

     return listings;
};

const App = (props) => {
     const classes = styles();
     const dispatch = useDispatch();

     //const [listings, setListings] = useState([]);
     const allCriteria = useSelector((s) => s.search.criteria);
     const existingQuery = useSelector((s) => s.search.query);
     const sortBy = useSelector((s) => s.search.sortBy);
     const listings = useSelector((s) => s.search.listings);
     const propertyTypes = useSelector((s) => s.search.propertyTypes);
     const location = useLocation();

     // effects
     // initialize all criteria
     /*
     useEffect(() => {
          dispatch({
               type: "INIT_ALL_SEARCH_CRITERIA",
          });
     }, []);
*/
     // handlers
     const setListings = (l) => {
          dispatch({
               type: "SET_MANY",
               data: [
                    {
                         path: "search.listings",
                         value: cloneDeep(l),
                    },
               ],
          });
     };

     const theme = createMuiTheme({
          typography: {
               fontSize: 12,
          },
     });

     if (window.theme == null) window.theme = theme;

     return (
          <ThemeProvider theme={theme}>
               <div id="content" className={classes.content}>
                    <Header />
                    <Route path="/" exact>
                         <Home />
                    </Route>
                    <Route path="/about" exact>
                         <About />
                    </Route>
                    <Route path="/search/:listingid?">
                         <SearchResults
                              extraCriteria={[createListAgent(null)]}
                         />
                    </Route>
                    <Route path="/waterfront_homes">
                         <SearchResults
                              autoSearch={true}
                              extraCriteria={[
                                   createWater(["all"]), // only waterfront
                                   createPropertyType(["A", "C", "D"], {
                                        propertyTypes: propertyTypes,
                                   }),
                                   createListAgent(null),
                              ]}
                         />
                    </Route>
                    <Route path="/luxury_homes">
                         <SearchResults
                              autoSearch={true}
                              extraCriteria={[
                                   createPrice([500, null]),
                                   createListAgent(null),
                              ]}
                         />
                    </Route>
                    <Route path="/land_listings">
                         <SearchResults
                              autoSearch={true}
                              extraCriteria={[
                                   createPropertyType(["C", "D"], {
                                        propertyTypes: propertyTypes,
                                   }),
                                   createListAgent(null),
                              ]}
                         />
                    </Route>
                    <Route path="/featured_listings">
                         <SearchResults
                              autoSearch={true}
                              extraCriteria={[
                                   createListAgent(
                                        "20140328190242470931000000"
                                   ),
                              ]}
                         />
                    </Route>
               </div>
          </ThemeProvider>
     );
};
export default App;
