import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import App from "Views/App";
import Page from "Components/Page";
import RealtyService from "Services/RealtyService";
import { HashRouter as Router } from "react-router-dom";

import * as serviceWorker from "serviceWorker";

import { Provider } from "react-redux";

import { Store } from "RootReducer";

window.store = Store;

//RealtyService.getToken(null, null);
RealtyService.init();

ReactDOM.render(
     <React.StrictMode>
          <Provider store={Store}>
               <Router>
                    <App />
               </Router>
          </Provider>
     </React.StrictMode>,
     document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
