import React, { useState, useCallback } from "react";
import * as ReactIs from "react-is";
import { Link } from "react-router-dom";

// misc
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     Divider,
     Drawer,
     IconButton,
     List,
     ListItem,
     ListItemIcon,
     ListItemText,
     Typography as Text,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";

import { IsSmall, IsMedium, IsLargePlus } from "MediaQuery";

const styles = makeStyles({
     menuButtonRoot: {
          color: "rgb(255,255,255)",
     },
     menuListItemRoot: {
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          // remove below line
          //color: "rgb(255,0,0)",
     },
     menuListTextLight: {
          color: "rgb(255,255,255)",
     },
     menuListTextDark: {
          color: "rgb(255,255,255)",
     },
     menuListRoot: {
          margin: "auto 16px auto auto",
          display: "flex",
     },
     menuListItemIcon: {
          border: "0px solid red",
          /*color: "rgb(255,255,255)",*/
          margin: "auto 8px auto auto",
          minWidth: 0,
     },
});

const sanitizeLink = (link) => {
     return link.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
};

const MenuItem = (props) => {
     const { item, path = null, onClick = null, classes: userClasses } = props;
     const classes = styles();

     const dispatch = useDispatch();

     const userItemRoot = get(userClasses, "itemRoot", "");
     const itemRoot = `${classes.menuListItemRoot} ${userItemRoot}`;

     const userIconRoot = get(userClasses, "iconRoot", "");
     const iconRoot = `${classes.menuListItemIcon} ${userIconRoot}`;

     // if item is a string, project it into the right shape

     const menuItem =
          typeof item == "string"
               ? {
                      text: item,
                      icon: null,
                      path: `/${sanitizeLink(path ?? item)}`,
                 }
               : item;

     let icon = item.icon ? (
          <ListItemIcon classes={{ root: iconRoot }}>{item.icon}</ListItemIcon>
     ) : null;

     let itemMarkup =
          ReactIs.isValidElementType(item) && typeof item != "string" ? (
               item
          ) : (
               <>
                    {" "}
                    {icon}
                    <ListItemText classes={{ root: itemRoot }}>
                         {menuItem.text}
                    </ListItemText>
               </>
          );

     const resetSearchState = useCallback(() => {
          if (onClick) onClick();

          // make an array of all the state updates we need to do
          let stateUpdates = [
               {
                    path: "search.listings",
                    value: [],
               },
               {
                    path: "search.hasSearched",
                    value: false,
               },
               {
                    path: "search.activeListing",
                    value: null,
               },
               {
                    path: "search.extraCriteriaSet",
                    value: false,
               },
          ];

          // do all the state updates at once
          dispatch({
               type: "SET_MANY",
               data: stateUpdates,
          });

          dispatch({
               type: "RESET_ALL_SEARCH_CRITERIA",
          });

          /*
          dispatch({
               type: "SET_MANY",
               data: [
                    {
                         path: "search.listings",
                         value: cloneDeep([]),
                    },
                    {
                         path: "search.criteria",
                         value: cloneDeep([]),
                    },
                    {
                         path: "search.ready",
                         value: false,
                    },
                    {
                         path: "search.activeListing",
                         value: null,
                    },
               ],
          });
          */
     }, [dispatch, onClick]);

     return (
          <ListItem
               button
               key={menuItem.path}
               component={Link}
               to={menuItem.path}
               onClick={resetSearchState}
          >
               {itemMarkup}
          </ListItem>
     );

     if (ReactIs.isValidElementType(item)) return React.createElement(item);
};

const MobileMenu = (props) => {
     const onToggleMenu = (open, e) => {
          dispatch({ type: "SET", path: "menuOpen", value: open });
     };

     const dispatch = useDispatch();
     const menuOpen = useSelector((state) => state.menuOpen);
     const classes = styles();

     let { menuItems, classes: userClasses } = props;

     return (
          <>
               <IconButton
                    classes={{ root: classes.menuButtonRoot }}
                    onClick={onToggleMenu.bind(null, true)}
               >
                    <MenuIcon fontSize="large" />
               </IconButton>
               <Drawer
                    open={menuOpen}
                    variant="temporary"
                    onClose={onToggleMenu.bind(null, false)}
               >
                    <List>
                         {menuItems.map((i) => (
                              <MenuItem
                                   item={i}
                                   classes={classes}
                                   onClick={onToggleMenu.bind(null, false)}
                              />
                         ))}
                    </List>
               </Drawer>
          </>
     );
};

const DesktopMenu = (props) => {
     const classes = styles();
     const { menuItems, classes: userClasses, menuItemClasses } = props;

     return (
          <List classes={{ root: classes.menuListRoot }}>
               {menuItems.map((i) => (
                    <MenuItem item={i} classes={menuItemClasses} />
               ))}
          </List>
     );
};

export const Menu = (props) => {
     const isSmall = useMediaQuery(IsSmall);
     const isMedium = useMediaQuery(IsMedium);
     const menuItems = useSelector((state) => state.tenant.menuItems);

     //const classes = styles();

     return isSmall || isMedium ? (
          <MobileMenu menuItems={menuItems} />
     ) : (
          <DesktopMenu
               menuItems={menuItems}
               menuItemClasses={props.menuItemClasses}
          />
     );
};

export default Menu;
