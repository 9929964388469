import React, { useState } from "react";
import * as ReactIs from "react-is";

import { get } from "lodash";

// redux
import { shallowEqual, useSelector } from "react-redux";

// material ui imports
import {
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography as Text,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { useMediaQuery } from "react-responsive";

// custom components

import { IsSmall, IsMedium, IsLargePlus } from "MediaQuery";

import Menu from "./Menu";
import SiteLogo from "./SiteLogo";

// custom styles

const styles = makeStyles({
	headerRoot: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		padding: "8px",
	},
	// these are custom overrides
	menuItemRoot: {
		color: "rgb(255,255,255)",
	},
	menuIconRoot: {
		color: "rgb(255,255,255)",
	},
});

export const Header = (props) => {
	const isSmall = useMediaQuery(IsSmall);
	const classes = styles();

	const { classes: userClasses } = props;

	let userRootClass = get(userClasses, "root", "");
	let rootClass = `${classes.headerRoot} ${userRootClass} `; //userRootClass ? userRootClass : classes.headerRoot;

	return (
		<div className={rootClass}>
			<SiteLogo />
			<Menu
				menuItemClasses={{
					itemRoot: classes.menuItemRoot,
					iconRoot: classes.menuIconRoot,
				}}
			/>
		</div>
	);
};

export default Header;
