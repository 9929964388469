import React, { useEffect, useState } from "react";

import { get } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import { cloneDeep, memoize } from "lodash";

import { useMediaQuery } from "react-responsive";

// components
import { Button, Dialog, Link, Typography } from "@material-ui/core";

import SearchCriteria from "Components/SearchCriteria/SearchCriteria";

import PriceCriteria, {
     getDefaults as getPriceDefaults,
     hasChanged as hasPriceChanged,
} from "Components/SearchCriteria/PriceCriteria";

import PropertyTypeCriteria, {
     getDefaults as getPropertyTypeDefaults,
     hasChanged as propertyTypeHasChanged,
} from "Components/SearchCriteria/PropertyTypeCriteria";

import NumberRangeCriteria, {
     getDefaults as getNumberRangeDefaults,
     hasChanged as numberRangeHasChanged,
} from "Components/SearchCriteria/NumberRangeCriteria";

import WaterCriteria, {
     getDefaults as getWaterDefaults,
} from "Components/SearchCriteria/WaterCriteria";

import Sort from "Components/Sort";

// icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import RealtyService from "Services/RealtyService";

import { didSearchChange, buildQuery, searchThunk } from "SearchUtility";

const styles = makeStyles({
     root: {
          display: "flex",
          //justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          //border: "1px solid blue",
     },
     criteriaRoot: {
          margin: "16px",
     },
     searchButton: {
          margin: "16px 16px 16px auto",
          background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          minWidth: "100px",
          flexWrap: "wrap",
     },
     resetButton: {
          margin: "16px",
     },
     search: {
          display: "flex",
     },
});

export const MobileFilter = (props) => {
     const { onBack, open = true } = props;

     const classes = styles();

     const dispatch = useDispatch();
     const currentSearch = useSelector((s) => s.search);
     const [initialSearch, setInitialSearch] = useState(null);

     // handles
     const handleBack = () => {
          if (onBack) onBack();

          // searchThunk will only fire if the query/sort/paging have changed
          dispatch(searchThunk);
     };

     const handleReset = () => {
          // iterate through each and reset
          dispatch({
               type: "RESET_ALL_SEARCH_CRITERIA",
               exclude: ["City"],
          });

          dispatch({
               type: "RESET_SORT",
          });
     };

     useEffect(() => {
          if (open) {
               let search = cloneDeep(currentSearch);
               search.query = buildQuery(search.criteria);
               setInitialSearch(search);
          }
     }, [open]);

     /*
     useEffect(() => {
          if (open) {
               const query = buildQuery(searchState.criteria);
               const lastQuery = searchState.query;

               const queryChanged = query != lastQuery;
               const sortChanged =
                    JSON.stringify(searchState.sortBy) != initialSort;

               console.log(
                    "MobileFilter hasChanged",
                    queryChanged,
                    sortChanged,
                    query,
                    "--",
                    lastQuery,
                    "--",
                    searchState.criteria
               );
               setHasChanged(queryChanged || sortChanged);
          }
     }, [
          initialSort,
          searchState.criteria,
          searchState.sortBy,
          searchState.query,
     ]);
     */
     const hasChanged = didSearchChange(initialSearch, currentSearch);

     return (
          <Dialog fullScreen open={open} classes={{ root: classes.root }}>
               <div className={classes.root}>
                    <div className={classes.search}>
                         <Button
                              variant="outlined"
                              //startIcon={<SearchIcon />}
                              classes={{ root: classes.resetButton }}
                              onClick={handleReset}
                         >
                              Reset Filter(s)
                         </Button>
                         <Button
                              variant="contained"
                              color="primary"
                              classes={{ root: classes.searchButton }}
                              onClick={handleBack}
                         >
                              {hasChanged ? "Search" : "Back"}
                         </Button>
                    </div>
                    <div className={classes.criteriaRoot}>
                         <Sort usePopper={false} searchOnChange={false} />
                         <SearchCriteria
                              label="Price"
                              criteria="ListPrice"
                              getDefaults={getPriceDefaults}
                              usePopper={false}
                              searchOnChange={false}
                         >
                              <PriceCriteria />
                         </SearchCriteria>
                         <SearchCriteria
                              label="Property Type"
                              criteria="PropertyType"
                              getDefaults={getPropertyTypeDefaults}
                              usePopper={false}
                              searchOnChange={false}
                         >
                              <PropertyTypeCriteria />
                         </SearchCriteria>
                         <SearchCriteria
                              label="Beds"
                              criteria="BedsTotal"
                              getDefaults={getNumberRangeDefaults}
                              usePopper={false}
                              searchOnChange={false}
                         >
                              <NumberRangeCriteria range={5} />
                         </SearchCriteria>
                         <SearchCriteria
                              label="Baths"
                              criteria="BathsTotal"
                              getDefaults={getNumberRangeDefaults}
                              usePopper={false}
                              searchOnChange={false}
                         >
                              <NumberRangeCriteria range={5} />
                         </SearchCriteria>
                         <SearchCriteria
                              label="Water"
                              criteria="Water"
                              getDefaults={getWaterDefaults}
                              usePopper={false}
                              searchOnChange={false}
                         >
                              <WaterCriteria />
                         </SearchCriteria>
                    </div>
               </div>
          </Dialog>
     );
};

export default MobileFilter;
