import React, { useState, useEffect } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

// misc
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { useMediaQuery } from "react-responsive";

// redux
import { useSelector, useDispatch } from "react-redux";

// material ui imports
import { Button, Typography as Text, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DotsIcon from "@material-ui/icons/MoreHoriz";

// realty
import { searchThunk } from "SearchUtility";

const styles = makeStyles({
     root: {
          //border: '1px solid blue',
          //minWidth: '500px',
          display: "flex",
          flexShrink: 0,
     },
     current: (props) => ({
          border: `0px solid ${props.theme.palette.text.primary}`,
          backgroundColor: props.theme.palette.primary.light,
          color: props.theme.palette.primary.contrastText,
          "&:hover": {
               border: `0px solid ${props.theme.palette.text.primary}`,
               color: props.theme.palette.text.primary,
          },
     }),
     page: (props) => ({
          //backgroundColor: props.theme.palette.common.white,
          //color: props.theme.palette.text.primary,
     }),
     button: (props) => ({
          margin: "0px",
          padding: "0px",
          maxWidth: "40px",
          minWidth: "40px",
          minHeight: "32px",
          maxHeight: "32px",
          //border: "1px solid red !important",
     }),
});

export const Paging = (props) => {
     // props

     // hooks
     const theme = useTheme();
     const classes = styles({
          ...props,
          ...{ theme: theme },
     });
     const dispatch = useDispatch();
     const paging = useSelector((s) => s.search.paging);
     const location = useLocation();

     const dims = {
          criteria: useSelector((s) => s.dimensions.criteria),
          paging: useSelector((s) => s.dimensions.paging),
          searchBar: useSelector((s) => s.dimensions.searchBar),
     };

     // handlers
     const searchNewPageThunk = (page, dispatch, getState) => {
          dispatch({
               type: "SET",
               path: "search.paging.currentPage",
               value: page,
          });

          dispatch(searchThunk);
     };

     const handleClick = (i) => {
          dispatch(searchNewPageThunk.bind(null, i));

          // reset the results scrolling
          const element = document.getElementById("content");
          if (element.scrollTop > 60) {
               element.scrollTo({
                    top: 60,
                    left: 0,
                    behavior: "smooth",
               });
          }
     };

     // if there's not paging vars set, don't render
     if (paging.currentPage == null || paging.totalPages == 1) return null;

     // if there's no room, hide this (just as a test)
     let pagingWidth = get(dims, "paging[0]", 0);
     let searchBarWidth = get(dims, "searchBar[0]", 0);

     let markup = [];

     let start =
          Math.floor(paging.currentPage / paging.showNext) * paging.showNext;
     let currentPageLimit = start + paging.showNext;

     if (currentPageLimit > paging.totalPages)
          currentPageLimit = paging.totalPages;

     for (let i = start; i < currentPageLimit; i++) {
          let c =
               i == paging.currentPage
                    ? `${classes.page} ${classes.current}`
                    : `${classes.page}`;

          let click = i != paging.currentPage ? () => handleClick(i) : null;

          markup.push(
               <Button
                    className={`${c} ${classes.button}`}
                    onClick={click}
                    key={i}
               >
                    {i + 1}
               </Button>
          );
     }

     let prevDisabled = paging.currentPage == 0;
     let nextDisabled = paging.currentPage + 1 == paging.totalPages;

     let handleNext = !nextDisabled
          ? () => handleClick(paging.currentPage + 1)
          : null;

     let handlePrev = !prevDisabled
          ? () => handleClick(paging.currentPage - 1)
          : null;

     if (paging.totalPages == null || location.pathname == "/") return null;

     return (
          <div id="paging" className={classes.root}>
               <Button
                    disabled={prevDisabled}
                    onClick={handlePrev}
                    className={classes.button}
               >
                    <ArrowBackIcon />
               </Button>
               {paging.currentPage >= paging.showNext ? (
                    <Button
                         aria-label="next"
                         className={classes.button}
                         size="small"
                         onClick={() => {
                              let lowest = Math.floor(
                                   paging.currentPage / paging.showNext
                              );
                              let page = (lowest - 1) * paging.showNext;
                              handleClick(page);
                         }}
                    >
                         <DotsIcon />
                    </Button>
               ) : null}
               {markup}
               {currentPageLimit < paging.totalPages ? (
                    <>
                         <Button
                              aria-label="next"
                              className={classes.button}
                              size="small"
                              onClick={() => handleClick(currentPageLimit)}
                         >
                              <DotsIcon />
                         </Button>
                    </>
               ) : null}
               <Button
                    disabled={nextDisabled}
                    className={classes.button}
                    onClick={handleNext}
               >
                    <ArrowForwardIcon />
               </Button>
          </div>
     );
};

export default Paging;
