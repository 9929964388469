import axios from "axios";
import axiosRetry from "axios-retry";
import jwtDecode from "jwt-decode";

export default class RealtyService {
     //static host = RealtyService.getHost();
     //static token = null;
     static _axios = null;
     static tokenAttempts = 0;

     static async init() {
          RealtyService._axios = axios.create({
               baseURL: RealtyService.getHost(),
          });

          RealtyService._axios.interceptors.request.use(
               async (config) => {
                    // for non token requests, check for a token, fetch if missing
                    if (config.url != "api/auth/token") {
                         const token = await RealtyService.getToken();
                         config.headers["Authorization"] = `Bearer ${token}`;
                    }

                    return config;
               },
               (error) => {
                    console.error("RealtyService request error", error);
                    return Promise.reject(error);
               }
          );

          axiosRetry(RealtyService._axios, { retries: 3 });
     }

     static getHost() {
          if (process.env.NODE_ENV == "development")
               return "http://localhost:8080";

          // if prod, we'll be hosting the react build as static content so it'll have the same host
          return window.location.origin;
     }

     static evaluateResponse(response) {
          return Promise.resolve(response.data.data);
     }

     static async getToken(user, password) {
          if (window.sessionStorage.getItem("ssr_realty_token")) {
               // check token exp
               var jwt = jwtDecode(
                    window.sessionStorage.getItem("ssr_realty_token")
               );
               if (Date.now() <= jwt.exp * 1000) {
                    return Promise.resolve(
                         window.sessionStorage.getItem("ssr_realty_token")
                    );
               }
          }

          return RealtyService._axios
               .post(`api/auth/token`, {
                    user: user,
                    pass: password,
               })
               .then((response) => {
                    window.sessionStorage.setItem(
                         "ssr_realty_token",
                         response.data.data
                    );

                    return Promise.resolve(response);
               })
               .then(RealtyService.evaluateResponse);
     }

     //filter=City Eq 'Springfield' And PropertyType Eq 'A'&fields=City,ListPrice,Beds,Baths&limit=2&page=1`
     static async getListings(filter, fields, expand, orderby, limit, page) {
          let params = [];

          if (filter) params.push(`filter=${filter}`);

          if (fields) params.push(`fields=${fields}`);

          if (expand) params.push(`expand=${expand}`);

          if (orderby) params.push(`orderby=${orderby}`);

          if (limit) params.push(`limit=${limit}`);

          if (page) params.push(`page=${page}`);

          return RealtyService._axios
               .get(`api/listings?${params.join("&")}`)
               .then(RealtyService.evaluateResponse);
     }

     static async getListingById(id, fields, expand, details) {
          let params = [];

          if (fields != null) params.push(`fields=${fields}`);

          if (expand != null) params.push(`expand=${expand}`);

          if (details != null) params.push("detail=1");

          return RealtyService._axios
               .get(`api/listings/${id}?${params.join("&")}`)
               .then(RealtyService.evaluateResponse);
     }

     static async getMyListings(filter, fields, expand, orderby, limit, page) {
          let params = [];

          if (filter) params.push(`filter=${filter}`);

          if (fields) params.push(`fields=${fields}`);

          if (expand) params.push(`expand=${expand}`);

          if (orderby) params.push(`orderby=${orderby}`);

          if (limit) params.push(`limit=${limit}`);

          if (page) params.push(`page=${page}`);

          return RealtyService._axios
               .get(`api/my/listings?${params.join("&")}`)
               .then(RealtyService.evaluateResponse);
     }

     static async getListingPhotos(id) {
          return RealtyService._axios
               .get(`api/listings/${id}/photos`)
               .then(RealtyService.evaluateResponse);
     }

     static async getCustomFields(customProperties) {
          return RealtyService._axios
               .get(`api/metadata/customFields/${customProperties}`)
               .then(RealtyService.evaluateResponse);
     }
}
