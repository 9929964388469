import React, { useState, useEffect, useRef } from "react";
import * as ReactIs from "react-is";

// misc

import get from "lodash/get";

// custom hooks
import useOnScreen from "../Hooks/useOnScreen";

// material ui imports
import { Paper, Typography, Typography as Text } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";

const styles = {
     root: {
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
          height: "350px",
          margin: "0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          borderRadius: "4px",
          boxShadow:
               "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
     },
     placeholderText: {
          height: "12px",
          margin: "4px",
     },
     placeholder: {
          width: "100%",
          height: "100%",
          opacity: 0.5,
     },
     details: {
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          backgroundColor: "rgba(255,255,255,0.8)",
          textDecoration: "none",
     },
     price: {
          margin: "0 0 0 8px",
          color: "white",
          textShadow: "1px 1px 2px black",
     },
     listingInfo: {
          fontSize: "14px",
     },
     listingPrice: {
          fontSize: "24px",
     },
     w40: {
          width: "40%",
     },
     w60: {
          width: "50%",
     },
     text: {
          color: "green",
     },
};

const arePropsEqual = (prev, next) => {
     return get(prev, "listing.id", null) === get(next, "listing.id", null);
};

export const ListingTile = (props) => {
     const { classes: userClasses, listing } = props;

     // hooks
     const ref = useRef();
     const onScreen = useOnScreen(ref, "-100px");
     const [visible, setVisible] = useState(false);

     useEffect(() => {
          if (onScreen && !visible) setVisible(true);
     }, [onScreen, visible]);

     const fields = listing ? listing.StandardFields : {};

     let primaryPhoto = visible ? get(fields, "Photos[0]", null) : null;
     let primaryPhotoURI = primaryPhoto ? primaryPhoto.Uri640 : null;

     const styleProps = {
          isPlaceholder: listing == null,
          primaryPhotoURI: primaryPhotoURI,
     };

     // TODO: this is causing a re-render every time
     //const classes = styles(styleProps);

     const userRoot = get(userClasses, "root", "");

     let prefix = fields.StreetDirPrefix;
     let prefixLower = prefix;
     switch (prefixLower) {
          case "north":
          case "south":
          case "east":
          case "west":
               prefix = prefix[0];
     }

     let address = [
          `${fields.StreetNumber} ${prefix ?? ""} ${fields.StreetName} ${
               fields.StreetDirSuffix ?? ""
          }`.trim(),
          `${fields.City}, ${fields.StateOrProvince}`,
     ];

     let price = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
     }).format(fields.ListPrice);

     const bgStyle = { backgroundImage: `url("${primaryPhotoURI}")` };

     const BodyText = (props) => (
          <Typography
               variant="body1"
               style={{ color: window.theme.palette.text.primary }}
               {...props}
          >
               <>{props.children}</>
          </Typography>
     );

     let primaryDesc = null;
     switch (fields?.PropertyType) {
          case "A":
               primaryDesc = (
                    <BodyText>
                         <b>{fields.BedsTotal}</b> bed{" "}
                         <b>{fields.BathsTotal}</b> bath{" "}
                         <b>{Math.trunc(fields.BuildingAreaTotal)}</b> sq ft
                    </BodyText>
               );
               break;
          case "C":
          case "D":
               primaryDesc = (
                    <BodyText>
                         <b>
                              {fields.LotSizeArea} {fields.LotSizeUnits}
                         </b>
                    </BodyText>
               );
               break;
     }

     return listing == null ? (
          <div style={styles.root} className="shimmerWrapper" ref={ref}>
               <div className="animate" style={styles.placeholder}>
                    &nbsp;
               </div>
          </div>
     ) : (
          <div style={{ ...styles.root, ...bgStyle }} ref={ref}>
               <div style={styles.details}>
                    {primaryDesc}
                    <BodyText>{address[0]}</BodyText>
                    <BodyText>{address[1]}</BodyText>
               </div>

               <div style={styles.price}>
                    <BodyText style={styles.listingPrice}>
                         <b>{price}</b>
                    </BodyText>
               </div>
          </div>
     );
};

export default React.memo(ListingTile, arePropsEqual);
