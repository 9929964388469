import React, { useEffect, useState } from "react";
import * as ReactIs from "react-is";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";

import { Link } from "react-router-dom";

// misc
import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import {
     Divider,
     MenuList,
     MenuItem,
     Paper,
     Typography,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// services
import RealtyService from "Services/RealtyService";

import {
     IsSmall,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

const styles = makeStyles({
     root: (props) => ({
          display: "flex",
          flexDirection: "column",
          width: props.isSmall ? "100%" : "180px",
          height: props.isSmall ? "250px" : "400px",
          overflowX: "hidden",
          overflowY: "scroll",
          //border: "1px solid green",
     }),
     menuListRoot: (props) => ({
          //width: "100%",
          //overflowX: "hidden",
     }),
     text: {
          border: "1px solid red",
     },
     placeholder: {
          width: "100%",
          height: "100%",
          opacity: 0.75,
     },
});

export const getDefaults = (props) => {
     return [];
};

export const hasChanged = (prev, curr) => {
     if (prev == null || curr == null) return false;

     const _prev = sortBy(prev).join(",");
     const _curr = sortBy(curr).join(",");

     return _prev != _curr;
};

export const getDisplay = (value, props = {}) => {
     const { options = [] } = props;
     if (value.length == 0) return null;

     return value.length == 1
          ? options.find((o) => o.key == value[0])?.label
          : `Water (${value.length})`;
};

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

export const getQuery = (value, props) => {
     if (value.length == 0) return null;

     if (value.find((v) => v == "all")) return "WaterFrontYN eq true";

     // if users select all_river and all_lake, they really mean "all" :)
     if (
          value.find((v) => v == "all_lake") &&
          value.find((v) => v == "all_river")
     )
          return "WaterFrontYN eq true";

     // handle all river
     if (value.find((v) => v == "all_river")) {
          // we're going to fake this by filtering for only LakeRiverCreek
          const riverfront = props.options.filter(
               (o) =>
                    !o.key.toLowerCase().startsWith("all") &&
                    o.key.toLowerCase().includes("river")
          );

          const query = riverfront.map(
               (v) =>
                    `"General Property Description"."LakeRiverCreek" Eq '${v.key}'`
          );
          return `(${query.join(" or ")})`;
     }

     // handle all lake
     if (value.find((v) => v == "all_lake")) {
          // we're going to fake this by filtering for only LakeRiverCreek
          const lakefront = props.options.filter(
               (o) =>
                    !o.key.toLowerCase().startsWith("all") &&
                    o.key.toLowerCase().includes("lake")
          );

          const query = lakefront.map(
               (v) =>
                    `"General Property Description"."LakeRiverCreek" Eq '${v.key}'`
          );
          return `(${query.join(" or ")})`;
     }

     const query = value.map(
          (v) => `"General Property Description"."LakeRiverCreek" Eq '${v}'`
     );
     return `(${query.join(" or ")})`;
};

export const create = (value, props = {}) => {
     const {
          options = [
               {
                    key: "all",
                    label: "All Waterfront",
               },
               {
                    key: "all_lake",
                    label: "Lakefront",
               },
               {
                    key: "all_river",
                    label: "Riverfront",
               },
               // TODO: Add "All Lakefront", "All Riverfront"
          ],
     } = props;

     return {
          criteria: "Water",
          value: value,
          display: getDisplay(value, { options: options }),
          default: getDefaults(),
          query: getQuery(value, { options: options }),
          options: options,
     };
};

export const criteriaPropsSelector = (state, props) => {
     //console.log("WaterCriteria.criteriaPropsSelector", state, props);
     return { waterfrontOptions: state?.search?.waterfrontOptions, ...props };
};

export const WaterCriteria = (props) => {
     const { classes: userClasses, criteria, onEnter, label } = props;

     // styling
     const isSmall = useMediaQuery(IsSmall);
     const classes = styles({ isSmall: isSmall });

     const state = useSelector((s) =>
          s.search.criteria.find((c) => c.criteria == criteria)
     );
     const stateExists = state != null;

     const dispatch = useDispatch();

     // data loading (TODO: should this be inside of this component?)
     const loadOptions = async () => {
          const waterBodies = await RealtyService.getCustomFields(
               "LakeRiverCreek"
          );

          let _options = [
               {
                    key: "all",
                    label: "All Waterfront",
               },
               {
                    key: "all_lake",
                    label: "Lakefront",
               },
               {
                    key: "all_river",
                    label: "Riverfront",
               },
               {
                    key: "divider",
               },
          ];

          waterBodies.FieldList.forEach((w) => {
               _options.push({
                    key: w.Name,
                    label: w.Value,
               });
          });

          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: create(state.value ?? [], { options: _options }),
          });

          const waterfrontOptions = waterBodies.FieldList.map((w) => ({
               key: w.Name,
               label: w.Value,
          }));

          dispatch({
               type: "SET",
               path: "search.waterfrontOptions",
               value: cloneDeep(waterfrontOptions),
          });
     };

     useEffect(() => {
          if (stateExists) loadOptions();
     }, [stateExists]);

     // handlers
     const handleClick = (key, e) => {
          let newValue = cloneDeep(state.value);

          // is this key in the list of criteria?
          const index = newValue.indexOf(key);
          if (index == -1) {
               // if all, deselect all others
               if (key == "all") newValue = [key];
               else {
                    // add the new key
                    newValue.push(key);
                    // remove all if selected
                    const allIndex = newValue.indexOf("all");
                    if (allIndex != -1) newValue.splice(allIndex, 1);
               }
          } else {
               // remove
               newValue.splice(index, 1);
          }

          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: create(newValue, { options: cloneDeep(state.options) }),
               /*
               data: {
                    criteria: criteria,
                    value: newValue,
                    display: getDisplay(newValue, {
                         options: cloneDeep(state.options),
                    }),
                    default: cloneDeep(state.default),
                    query: getQuery(newValue, {
                         options: cloneDeep(state.options),
                    }),
               },
               */
          });
     };

     if (state == null) return null;

     const options = Array.isArray(state.options)
          ? state.options
          : [null, null, null, null, null, null, null, null];

     return (
          <Paper classes={{ root: classes.root }}>
               <MenuList classes={{ root: classes.menuListRoot }}>
                    {options.map((o) => {
                         if (o?.key == "divider") return <Divider />;

                         if (o == null) {
                              return (
                                   <MenuItem>
                                        <div
                                             className={`animate ${classes.placeholder}`}
                                        >
                                             &nbsp;
                                        </div>
                                   </MenuItem>
                              );
                         }

                         return (
                              <MenuItem
                                   onClick={handleClick.bind(null, o.key)}
                                   selected={state.value.includes(o.key)}
                              >
                                   {o.label}
                              </MenuItem>
                         );
                    })}
               </MenuList>
          </Paper>
     );
};

export default WaterCriteria;
