import React, { useEffect } from "react";
import * as ReactIs from "react-is";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";

import { Link } from "react-router-dom";

// misc
import { useMediaQuery } from "react-responsive";

// redux
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// material ui imports
import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
     IsSmall,
     IsMediumPlus,
     IsLargePlus,
     IsExtraLargePlus,
} from "MediaQuery";

const styles = makeStyles({
     root: {
          display: "flex",
     },
});

const criteriaSelector = (id, state) => {
     const index = findIndex(state.search.criteria, { criteria: id });
     return index == -1 ? null : state.search.criteria[index];
};

export const getDefaults = (props) => {
     return null;
};

export const hasChanged = (prev, curr) => {
     return prev != curr;
};

export const getDisplay = (value, props = {}) => {
     const { label } = props;

     if (value == null) return null;
     return `${value}+ ${label}`;
};

export const getQuery = (value, props = {}) => {
     const { criteria } = props;

     if (value == null) return null;
     return `${criteria} Ge ${value}`;
};

export const create = (value, props = null) => {
     const { criteria } = props;

     return {
          criteria: criteria,
          value: value,
          display: getDisplay(value, props),
          default: value,
          query: getQuery(value, props),
     };
};

export const NumberRangeCriteria = (props) => {
     const { classes: userClasses, criteria, onEnter, label, range } = props;

     const classes = styles();
     const state = useSelector(criteriaSelector.bind(null, criteria));
     const dispatch = useDispatch();

     // handlers
     const handleClick = (i) => {
          const criteriaProps = { criteria: criteria, label: label };

          dispatch({
               type: "SET_SEARCH_CRITERIA",
               data: {
                    criteria: criteria,
                    value: i,
                    display: getDisplay(i, criteriaProps),
                    default: null,
                    query: getQuery(i, criteriaProps),
               },
          });
          onEnter();
     };

     if (state == null) return null;

     let buttonMarkup = [];
     for (let i = 1; i < range + 1; i++) {
          buttonMarkup.push(
               <Button
                    key={i}
                    onClick={() => handleClick(i)}
                    variant={i == state.value ? "outlined" : null}
               >
                    {i} +
               </Button>
          );
     }
     return <div className={classes.root}>{buttonMarkup}</div>;
};

export default NumberRangeCriteria;
