import cloneDeep from "lodash/cloneDeep";
import RealtyService from "Services/RealtyService";

export const buildQuery = (allCriteria = []) => {
     // build the query
     var allQueries = [];

     allCriteria.forEach((c) => {
          if (c.query != null && c.query.length > 0) {
               allQueries.push(c.query);
          }
     });

     return allQueries.join(" And ");
};

export const didSearchChange = (prevSearch, nextSearch) => {
     // if there's no previous search, or next search, or no criteria
     // present, we know search didn't change it's still being setup
     // in state
     if (prevSearch == null || nextSearch == null) return false;

     if (nextSearch.hasSearched === false) return true;

     const queryChanged = prevSearch.query != nextSearch.query;

     const pagingChanged =
          prevSearch.paging.currentPage != nextSearch.paging.currentPage ||
          prevSearch.paging.resultsPerPage != nextSearch.paging.resultsPerPage;

     const sortChanged =
          prevSearch.sortBy?.property != nextSearch.sortBy?.property;

     return queryChanged || pagingChanged || sortChanged;
};

export const searchThunk = async (dispatch, getState) => {
     const currentState = getState();

     if (currentState.search.searching === true) return;

     const changed = didSearchChange(
          currentState.prevSearch,
          currentState.search
     );

     console.log("searchThunk changed", changed);

     const searchFunc = RealtyService.getListings;

     if (changed === true) {
          dispatch({
               type: "SET_MANY",
               data: [
                    {
                         path: "search.listings",
                         value: cloneDeep([
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                         ]),
                    },
                    {
                         path: "search.autoSearch",
                         value: false,
                    },
                    {
                         path: "search.searching",
                         value: true,
                    },
               ],
          });

          // always clone the prev state before modifying
          dispatch({ type: "SET_PREV_SEARCH" });

          console.log(`SEARCHING ${currentState.search.query}`);

          // now search
          const listings = await searchFunc(
               currentState.search.query,
               null,
               "PrimaryPhoto",
               currentState.search.sortBy?.property ?? null,
               currentState.search.paging.resultsPerPage ?? 20,
               currentState.search.paging.currentPage != null
                    ? currentState.search.paging.currentPage + 1
                    : 1
          );

          // make an array of all the state updates we need to do
          let stateUpdates = [
               {
                    path: "search.listings",
                    value: listings.Results,
               },
               {
                    path: "search.hasSearched",
                    value: true,
               },
               {
                    path: "search.searching",
                    value: false,
               },
          ];

          // reset paging since the query has changed
          stateUpdates.push({
               path: "search.paging",
               value: {
                    currentPage: listings.Pagination.CurrentPage - 1,
                    resultsPerPage: listings.Pagination.PageSize,
                    totalPages: listings.Pagination.TotalPages,
                    showNext: 5,
               },
          });

          // do all the state updates at once
          dispatch({
               type: "SET_MANY",
               data: stateUpdates,
          });
     }
};
